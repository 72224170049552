import axios from "axios";
import Cookies from "js-cookie";

const auth = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: ""
  },
  getters: {},
  mutations: {
    REGISTER(state, data) {
      state.solveMsg = data;
    },
    LOGIN(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
    RESET_MSG(state, data) {
      state.errMsg = data;
      state.solveMsg = data;
    },
    DO_NOTHING(data) {
      console.log(data);
    }
  },
  actions: {
    //register
    register(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/daftar`, data)
          .then(res => {
            let data = res.data;
            context.commit("REGISTER", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            reject(error.response.data);
          });
      });
    },
    //register public
    registerPublic(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/register`, data)
          .then(res => {
            let data = res.data;
            context.commit("REGISTER", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            reject(error.response.data);
          });
      });
    },
    //login
    login(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/auth/login`, data)
          .then(res => {
            let data = res.data;
            if (data.status == "success") {
              let token = data.data.token;
              let id = data.data.user.id;
              let role = data.data.user.role_id;
              let status = data.data.user.status;

              // set data to cookies
              Cookies.set("token", token, { expires: 7 });  
              Cookies.set("id", id, { expires: 7 });
              Cookies.set("role", role, { expires: 7 });
              Cookies.set("status", status, { expires: 7 });
            }
            context.commit("LOGIN", data);
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    //resend email verivikasi/password
    resend(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/general/resend-verifikasi`, data)
          .then(res => {
            let data = res.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("DO_NOTHING", error.response);
            reject(error.response);
          });
      });
    },

    //logout
    logout(context) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_ENV;
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        axios
          .post(`${API_URL}/auth/logout`, config)
          .then(res => {
            resolve(res);
            Cookies.remove("token");
            Cookies.remove("role");
            Cookies.remove("id");
            localStorage.clear();
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    // LOGIN GOOGLE
    googleSign(context, data) {
      return new Promise((resolve, reject) => {
        console.log("CAPE MIKIRIN HIDUP");
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/auth/login-google`, data)
          .then(res => {
            console.log(res);
            let data = res.data;
            if (data.status == "success") {
              let token = data.data.token;
              let id = data.data.user.id;
              let role = data.data.user.role_id;
              let status = data.data.user.status;

              // set data to cookies
              Cookies.set("token", token, { expires: 7 });
              Cookies.set("id", id, { expires: 7 });
              Cookies.set("role", role, { expires: 7 });
              Cookies.set("status", status, { expires: 7 });
            }
            context.commit("LOGIN", data);
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // PUBLIC REGISTER GOOGLE
    userGoogleSignup(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/daftar-google`, data)
          .then(res => {
            let data = res.data;
            if (data.status == "success") {
              let token = data.data.token;
              let id = data.data.user.id;
              let role = data.data.user.role_id;
              let status = data.data.user.status;

              // set data to cookies
              Cookies.set("token", token, { expires: 7 });
              Cookies.set("id", id, { expires: 7 });
              Cookies.set("role", role, { expires: 7 });
              Cookies.set("status", status, { expires: 7 });
            }
            context.commit("LOGIN", data);
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            // context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // PUBLIC REGISTER GOOGLE
    publicGoogleSignup(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/register-google`, data)
          .then(res => {
            let data = res.data;
            if (data.status == "success") {
              let token = data.data.token;
              let id = data.data.user.id;
              let role = data.data.user.role_id;
              let status = data.data.user.status;

              // set data to cookies
              Cookies.set("token", token, { expires: 7 });
              Cookies.set("id", id, { expires: 7 });
              Cookies.set("role", role, { expires: 7 });
              Cookies.set("status", status, { expires: 7 });
            }
            context.commit("LOGIN", data);
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            // context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    }
  }
};
export default auth;
