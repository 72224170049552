import axios from "axios";
import Cookies from "js-cookie";

const agenda = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    agenda: null,
    agendaDetail: null,
    agendaParticipant: null,
    participantPublic: null,
    participatedEvt: null,
  },
  getters: {},
  mutations: {
    AGENDA(state, data) {
      state.agenda = data;
    },
    AGENDA_BYID(state, data) {
      state.agendaDetail = data;
    },
    AGENDA_PARTICIPANT(state, data) {
      state.agendaParticipant = data;
    },
    AGENDA_PARTICIPANT_PUBLIC(state, data) {
      state.participantPublic = data;
    },
    PARTICIPATED_EVT_PSY(state, data) {
      state.participatedEvt = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    //AGEnda

    //list all agenda
    listAgenda(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let search = data.search ? data.search : "";
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/agenda?page[number]=${data.page}&page[size]=${data.limit}&filter[agenda]=${search}`, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("AGENDA", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    publicAgenda(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/agenda`, config)
          .then((res) => {
            resolve(res.data.data);
            const data = res.data;
            context.commit("AGENDA", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    publicAgendaView(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        // let search = data.search
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/agenda?filter[id]=${data}`, config)
          .then((res) => {
            resolve(res.data.data);
            const data = res.data;
            context.commit("AGENDA_BYID", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //agenda by id
    AgendaById(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/agenda/${data}/view`, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("AGENDA_BYID", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create agenda.
    createAgenda(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/agenda/create`, data, config)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update agenda.
    updateAgenda(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.data.id;
        axios
          .post(`${API_URL}/admin/agenda/${id}/update`, data.data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    updatePayer(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/agenda/payment`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete agenda by id.
    deleteAgenda(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data;
        axios
          .post(`${API_URL}/admin/agenda/${id}/delete`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    listParticipant(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let search = data.search;
        let id = parseInt(data.id);
        let API_URL = process.env.VUE_APP_ENV;
        let path = ``;
        if (search == "") {
          path = `${API_URL}/admin/agenda/${id}/participant?include=dataUser,dataPayment`;
        } else {
          path = `${API_URL}/admin/agenda/${id}/participant?include=dataUser,dataPayment&filter[participant]=${search}`;
        }
        axios
          .get(path, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("AGENDA_PARTICIPANT", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    listParticipantPublic(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = process.env.VUE_APP_ENV;
        axios
          .get(
            `${env}/general/agenda/${data}/participant?include=dataPayment`,
            config,
          )
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("AGENDA_PARTICIPANT_PUBLIC", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    joinEvtPublic(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let id = data.id;
        let body = data.body;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/general/agenda?agenda_id=${id}`, body, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error);
          });
      });
    },
    joinEvtUserPublic(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/general/agenda`, data, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error);
          });
      });
    },
    joinEvtPsy(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/agenda/${data.id_evt}/psikolog/${data.id_user}`,
            config,
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error);
          });
      });
    },
    participatedEvtPsy(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/agenda/join?include=dataAgenda,dataPayment`,
            config,
          )
          .then((res) => {
            resolve(res.data);
            context.commit("PARTICIPATED_EVT_PSY", res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error);
          });
      });
    },
    payEvt(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/general/agenda/payment`, data, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            reject(error);
          });
      });
    },
  },
};

export default agenda;
