import axios from "axios";
import Cookies from "js-cookie";

const admin = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    psycholog: null,
    registran: null,
    unregistran: null,
    klinis: null,
    pendidikan: null,
    industri: null,
    loading: false,
    activity: null,
    last_page: 1,
    artikel: null,
    survey: null,
    psikologMan: null,
    psikologWoman: null,
    psikologProv: null,
  },
  getters: {},

  mutations: {
    PSYCHOLOG(state, data) {
      state.psycholog = data;
    },
    GET_ACTIVITY(state, data) {
      // state.activity = data;
      state.last_page = data.meta.last_page;
    },
    REGISTRAN(state, data) {
      state.registran = data;
    },
    UNREGISTRAN(state, data) {
      state.unregistran = data;
    },
    KLINIS(state, data) {
      state.klinis = data.meta.total;
    },
    PENDIDIKAN(state, data) {
      state.pendidikan = data.meta.total;
    },
    INDUSTRI(state, data) {
      state.industri = data.meta.total;
    },
    ARTIKEL(state, data) {
      state.artikel = data;
    },
    SURVEY(state, data) {
      state.survey = data;
    },
    PSIKOLOG_MAN(state, data) {
      state.psikologMan = data;
    },
    PSIKOLOG_WOMAN(state, data) {
      state.psikologWoman = data;
    },
    PSIKOLOG_PROVINSI(state, data) {
      state.psikologProv = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },

  actions: {
    listPsyholog(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let page = data.page;
        let limit = data.per_page;
        let search = data.search;
        let type = data.type;
        let value = data.value;
        let sort = data.sort;
        let bidang = data.bidang;
        let API_URL = process.env.VUE_APP_ENV;
        let path = ``;
        if (type != "all") {
          path = `${API_URL}/admin/list-psikolog/${type}/${value}?filter[users]=${search}&page[number]=${page}&page[size]=${limit}`;
        } else {
          path = `${API_URL}/admin/list-psikolog/all/list?filter[nama_lengkap]=${search}&page[number]=${page}&page[size]=${limit}&filter[bidang_id]=${bidang}&sort=${sort}`;
        }
        axios
          .get(path, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("PSYCHOLOG", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list registran
    listRegistran(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/list-registran`, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("REGISTRAN", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    listUserPublic(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/public?page[number]=${data.page}&page[size]=${data.limit}&filter[nama_lengkap]=${data.find}`,
            config,
          )
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //unlist registran
    unlistRegistran(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/unlist-registran`, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("UNREGISTRAN", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // confirm registran
    confirmRegistran(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/confirm-registran`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    rejectRegistran(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/ignore-registran/${data.id}`, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // import importlist
    importAlumni(context, data) {
      return new Promise((resolve) => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        console.log(data);
        axios
          .post(`${API_URL}/admin/import-alumni`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
            context.state.loading = false;
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            context.state.loading = false;
          });
      });
    },

    importUser(context, data) {
      return new Promise((resolve) => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        console.log(data);
        axios
          .post(`${API_URL}/admin/import-user`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
            context.state.loading = false;
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
            context.state.loading = false;
          });
      });
    },
    // ---------------------------------------------------------- FOR SUMMARY ON DASHBOARD

    getSummary(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/list-psikolog/all/list?filter[bidang_id]=${id}`,
            config,
          )
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            if (id == 1) {
              context.commit("KLINIS", data);
            } else if (id == 2) {
              context.commit("INDUSTRI", data);
            } else {
              context.commit("PENDIDIKAN", data);
            }
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    getModelSummary(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        if (data == "Artikel") {
          axios
            .get(`${API_URL}/general/jumlah/${data}`, config)
            .then((res) => {
              resolve(res.data);
              const data = res.data;
              context.commit("ARTIKEL", data);
            })
            .catch((error) => {
              context.commit("ERR_MSG", error.response);
              console.log(error);
            });
        }
        if (data == "Survey") {
          axios
            .get(`${API_URL}/general/jumlah/${data}`, config)
            .then((res) => {
              resolve(res.data);
              const data = res.data;
              context.commit("SURVEY", data);
            })
            .catch((error) => {
              context.commit("ERR_MSG", error.response);
              console.log(error);
            });
        }
      });
    },

    getUserSummary(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        if (data.child == "Laki-laki") {
          axios
            .get(
              `${API_URL}/general/jumlah/${data.model}?child=${data.child}`,
              config,
            )
            .then((res) => {
              resolve(res.data);
              const data = res.data;
              context.commit("PSIKOLOG_MAN", data);
            })
            .catch((error) => {
              context.commit("ERR_MSG", error.response);
              console.log(error);
            });
        }
        if (data.child == "Perempuan") {
          axios
            .get(
              `${API_URL}/general/jumlah/${data.model}?child=${data.child}`,
              config,
            )
            .then((res) => {
              resolve(res.data);
              const data = res.data;
              context.commit("PSIKOLOG_WOMAN", data);
            })
            .catch((error) => {
              context.commit("ERR_MSG", error.response);
              console.log(error);
            });
        }
        if (data.child == "Provinsi") {
          axios
            .get(
              `${API_URL}/general/jumlah/${data.model}?child=${data.child}`,
              config,
            )
            .then((res) => {
              resolve(res.data);
              const data = res.data;
              context.commit("PSIKOLOG_PROVINSI", data);
            })
            .catch((error) => {
              context.commit("ERR_MSG", error.response);
              console.log(error);
            });
        }
      });
    },

    // log aktiviats
    log(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/activity?include=dataUser&sort=-created_at&page[number]=${data.page}&page[size]=${data.limit}`,
            config,
          )
          .then((res) => {
            resolve(res.data);
            const log = res.data;
            context.commit("GET_ACTIVITY", log);
            if (data.page > 1) {
              context.state.activity.push(...log.data);
            } else {
              context.state.activity = log.data;
            }
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
  },
};
export default admin;
