import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/css/style.css";
import Notifications from "vue-notification";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import VueTippy, { TippyComponent } from "vue-tippy";
import "tippy.js/themes/light.css";
import VueSnip from "vue-snip";
import VueCarousel from "vue-carousel";
import "./plugins/Dayjs";
import VueObserveVisibility from "vue-observe-visibility";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css"; // import the croppie css manually
import VueSocialSharing from "vue-social-sharing";
import VueApexCharts from "vue-apexcharts";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(VueApexCharts);
Vue.use(Toast, {
  draggable: true,
  timeout: 5000
});

Vue.component("apexchart", VueApexCharts);

// firebase config
import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import firebaseConfig from "./plugins/firebase/config";

firebase.initializeApp(firebaseConfig);

Vue.use(VueSocialSharing);
Vue.use(VueCroppie);
Vue.use(VueObserveVisibility);
Vue.use(VueSnip);
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
Vue.use(VueCarousel);
Vue.use(Notifications);
// ========================================================================== GOOGLE AUTH
import GAuth from "vue-google-oauth2";
const gauthOption = {
  clientId:
    "891149751164-fq0crtfico6kinqeg33uv9aqf3r0trrq.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "consent",
  access_type: "offline"
};
Vue.use(GAuth, gauthOption);

AOS.init({
  disable: "phone"
});

Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount("#app");
