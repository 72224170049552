<template>
  <div>
    <div class="mt-4 __input">
      <v-text-field
        solo
        dense
        type="search"
        autocomplete="off"
        label="Search Psychologist"
        rounded
        append-icon="mdi-magnify"
        hide-details
        flat
        v-model="find"
        background-color="#ECEFF1"
        @keyup="search"
        @focus="isInput"
      ></v-text-field>
    </div>
    <div class="__search__result">
      <v-card class="radius-card py-3" width="100%">
        <div v-if="result">
          <section v-for="item in result.data" :key="item.id">
            <div class="d-flex pa-2 __item" @click="toView(item)">
              <v-avatar class="mr-3">
                <img
                  :src="
                    item.photo_profile
                      ? `${env}/upload/photo_profile/${item.id}/${item.photo_profile}`
                      : dummy
                  "
                  alt=""
                  class="img-fit"
                />
              </v-avatar>
              <div>
                <h4 class="name__">{{ item.nama_lengkap }}</h4>
                <div v-for="(val, key) in item.relationships" :key="key">
                  <small class="grey--text" v-if="val.bidang.data">
                    {{ val.bidang.data.nama }}
                  </small>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </section>
          <div v-if="!result.data.length" class="px-3">
            <h3>Tidak dapat menemukan {{ find }}</h3>
          </div>
        </div>
        <div class="mx-3 grey--text d-flex" v-else>
          <v-icon>mdi-magnify</v-icon>
          <h4>Ketik Nama Psycholog yang Anda cari</h4>
        </div>
        <div class="d-flex justify-center">
          <v-progress-circular
            :size="30"
            :width="5"
            color="blue"
            indeterminate
            v-if="loading"
          ></v-progress-circular>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "searchOnNav",
  computed: {
    ...mapState({
      result: state => state.findPsycho,
      env: state => state.API_URL,
      dummy: state => state.dummy
    })
  },
  data() {
    return {
      find: "",
      loading: false
    };
  },

  created() {
    document.addEventListener("click", function(event) {
      let isClickInside = document
        .querySelector(".__search__result")
        .contains(event.target);
      let isClickInput = document
        .querySelector(".__input")
        .contains(event.target);

      if (!isClickInside && !isClickInput) {
        document.querySelector(".__search__result").style.display = "none";
      }
    });
  },
  methods: {
    // search psikolog
    search() {
      this.loading = true;
      this.$store.dispatch("findPsycholog", this.find).then(() => {
        this.loading = false;
      });
    },
    // view sikolog by username
    toView(item) {
      console.log(item);
      this.$router.push(`/pam/${item.id}`);
      document.querySelector(".__search__result").style.display = "none";
      this.find = "";
    },

    isInput() {
      document.querySelector(".__search__result").style.display = "block";
    }
  }
};
</script>

<style scoped>
.__search__result {
  display: none;
  position: fixed;
  top: 12vh;
  width: 40%;
  /* overflow: hidden; */
}
.name__ {
  color: #424242;
}
.__item:hover {
  background: #eeeeee;
  cursor: pointer;
}
</style>
