import axios from "axios";
import Cookies from "js-cookie";

const friendship = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    friends: null,
    request: null,
    blocked: null,
    counterRequest: null
  },
  getters: {},
  mutations: {
    FRIENDS(state, data) {
      state.friends = data;
    },
    REQUEST(state, data) {
      state.request = data;
      state.counterRequest = data.data.length;
    },
    BLOCKED(state, data) {
      state.blocked = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //BIDANG

    //list all FRIENDS
    listFriends(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/friends?page=${data.page}&per_page=${data.limit}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FRIENDS", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all REQUEST
    listRequest(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/friends-request`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("REQUEST", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all BLOCKED
    listBlocked(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/blocked-friends`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("BLOCKED", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // send request pertemanan
    sendRequest(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/user/friends-request

          `,
            data,
            config
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // aprove request pertemanan
    aproveRequest(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/friends-request-approve`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // reject request pertemanan
    rejectRequest(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/friends-request-reject`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update bidang.
    unfriend(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/unfriend`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // block friends
    blockFriends(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/friend-block`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // unblock friends
    unblockFriends(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/friend-unblock`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};
export default friendship;
