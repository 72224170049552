import axios from "axios";
import Cookies from "js-cookie";

const faq = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    faq: null,
    faqID: null,
    category: null
  },
  getters: {},
  mutations: {
    FAQ(state, data) {
      state.faq = data;
    },
    FAQ_BYID(state, data) {
      state.vacantID = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //faq

    //list all faq
    listFaq(context) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/faq`)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FAQ", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //faq by id
    getFaqById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/faq?filter[id]=${data}`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FAQ_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create faq.
    createFaq(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/faq/create`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update faq.
    updateFaq(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/faq/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete faq by id.
    deleteFaq(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/faq/${data.id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};

export default faq;
