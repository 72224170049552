<template>
  <div
    class="_bg-default pa-3 _20w _sidebar _100vh d-flex flex-column align-end"
  >
    <v-card class="radius-card mb-3 _100top" width="300px">
      <v-list dense>
        <v-list-item active-class="blue--text" link to="/admin/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="blue--text" link to="/psikolog/feed">
          <v-list-item-icon>
            <v-icon>mdi-comment-quote</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Feed</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-account">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Psychologist</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="blue--text"
            link
            to="/admin/list-psikolog/all/list"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Psychologist List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item active-class="blue--text" link to="/psikolog/forum">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Forum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="blue--text" link to="/psikolog/library">
          <v-list-item-icon>
            <v-icon>mdi-bookshelf</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Library</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          active-class="blue--text"
          link
          to="/admin/survey-management"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Survey</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="blue--text" link to="/psikolog/vacancies">
          <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vacancies</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SidebarAdmin",

  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
._sidebar {
  position: fixed;
}
.scrollable {
  height: 450px;
  overflow: hidden;
}
.scrollable:hover {
  overflow-y: scroll;
}
.min-img {
  margin-top: -50px;
  border: 2px white solid;
}
.top {
  margin-top: 10vh;
}
</style>
