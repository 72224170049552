<template>
  <div
    class="_bg-default pa-3 _20w _sidebar _100vh d-flex flex-column align-end"
  >
    <v-card
      class="radius-card _100top mb-3 _decoretion_none white"
      width="300px"
      v-if="profile"
      v-ripple="{ class: `purple--text` }"
      @click.native="toProfile"
    >
      <v-img src="../../../assets/img/meptest.jpeg" height="60px" class="pa-2">
      </v-img>
      <div class="d-flex justify-center">
        <v-avatar color="white" size="100" class="min-img">
          <img
            :src="profile.photo_profile ? avatar : dummy"
            class="img-fit"
            alt="asd"
          />
        </v-avatar>
      </div>
      <div class="text-center px-10 py-2">
        <h3>{{ profile.nama_lengkap }}</h3>
        <section v-if="field">
          <small> {{ field.nama }} di {{ location1.nama_kota }}</small>
        </section>
      </div>
    </v-card>

    <v-card class="radius-card sticky" width="300px">
      <v-list dense>
        <v-list-item
          v-if="isVerified && isVerified.status == 1"
          active-class="blue--text"
          link
          to="/psikolog/counseling"
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Counseling</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="blue--text" link to="/psikolog/forum">
          <v-list-item-icon>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Forum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="blue--text" link to="/psikolog/feed">
          <v-list-item-icon>
            <v-icon>mdi-comment-quote</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Feed</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-bookshelf">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Library</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item active-class="blue--text" link to="/psikolog/library">
            <v-list-item-icon>
              <v-icon>mdi-book-open-blank-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>My Library</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="blue--text"
            link
            to="/psikolog/public-library"
          >
            <v-list-item-icon>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Public Library</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item active-class="blue--text" link to="/psikolog/list-survey">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Survey</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-briefcase-variant">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Vacancies</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item active-class="blue--text" link to="/psikolog/vacancies">
            <v-list-item-icon>
              <v-icon>mdi-briefcase</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>List Vacancies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="blue--text"
            link
            to="/psikolog/my-vacancies"
          >
            <v-list-item-icon>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>My Vacancies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="blue--text"
            link
            to="/psikolog/list-applicant"
          >
            <v-list-item-icon>
              <v-icon>mdi-human-queue</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>List Applicant</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item active-class="blue--text" link to="/psikolog/event">
          <v-list-item-icon>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Event</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SidebarPsycholog",
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapState({
      profile: state => state.psycholog.myProfile,
      env: state => state.API_URL,
      location1: state => state.psycholog.location,
      field: state => state.psycholog.field,
      isVerified: state => state.counseling.isVerified,
      dummy: state => state.dummy
    }),
    avatar() {
      let data = null;
      if (this.profile) {
        data = `${this.env}/upload/photo_profile/${this.profile.id}/${this.profile.photo_profile}`;
      }
      return data;
    }
  },
  props: ["toggleMini"],
  data() {
    return {
      sidebarMenu: true,
      banner: ""
    };
  },
  mounted() {
    this.$store.dispatch("counseling/chekRequest");
  },
  methods: {
    toProfile() {
      console.log(this.$route.path);
      if (this.$route.path !== "/psikolog/personal") {
        this.$router.push("/psikolog/personal");
      }
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
</script>

<style scoped>
._sidebar {
  position: absolute;
  height: 100%;
}
.sticky {
  position: sticky;
  top: 90px;
}
.scrollable {
  height: 300px;
  overflow: hidden;
}
.scrollable:hover {
  overflow-y: scroll;
}
.min-img {
  margin-top: -50px;
  border: 5px white solid;
}
.top {
  margin-top: 10vh;
}
._decoretion_none {
  text-decoration: none;
}
._decoretion_none:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  ._sidebar {
    display: none !important;
  }
}
@media (max-width: 576px) {
  ._sidebar {
    display: none !important;
  }
}
</style>
