import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Swal from "sweetalert2";

import auth from "./Module/auth";
import admin from "./Module/admin";
import campus from "./Module/campus";
import psycholog from "./Module/psycholog";
import area from "./Module/area";
import fields from "./Module/field";
import feeds from "./Module/feeds";
import library from "./Module/library";
import friendship from "./Module/friendship";
import agenda from "./Module/agenda";
import forum from "./Module/forum";
import vacancies from "./Module/vacancies";
import survey from "./Module/survey";
import faq from "./Module/FAQ";
import notification from "./Module/notification";
import merchandise from "./Module/merchandise";
import aboutus from "./Module/aboutus";
import cust from "./Module/cust";
import quiz from "./Module/quiz";
import counseling from "./Module/counseling";
import moodtracker from "./Module/moodtracker";

import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: Cookies.get("token") || null, //token
    role: Cookies.get("role") || null, //role
    id: Cookies.get("id"),
    status: Cookies.get("status"),
    errMsg: "",
    solveMsg: "",
    filteredPsycho: null,
    findPsycho: null,
    person: null,
    allPsychologForPublic: null,
    colleaguess: null,
    API_URL: process.env.VUE_APP_ENV,
    texted: "",
    last_page_kolega: null,
    last_page_allPsychologForPublic: null,
    feedback: null,
    dummy: require("../assets/img/ava.png"),
  },
  getters: {
    getUser(state) {
      return state.token !== null && state.token !== undefined;
    },
  },
  mutations: {
    CHECK_THEN(state, data) {
      state.texted = data;
    },
    ALL_PSYCHO(state, data) {
      state.last_page_allPsychologForPublic = data.meta.last_page;
    },
    COLLEAGUESS(state, data) {
      // state.colleaguess = data;
      state.last_page_kolega = data.meta.last_page;
    },
    PSYCHOLOG_VIEW(state, data) {
      state.person = data;
    },
    FILTERED(state, data) {
      state.filteredPsycho = data;
    },
    FIND(state, data) {
      state.findPsycho = data;
    },
    FEEDBACK(state, data) {
      state.feedback = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    change_tes(context, data) {
      context.commit("CHECK_THEN", data);
    },
    // psycholog change photo profile
    changePic(context, data) {
      return new Promise((resolve, reject) => {
        // console.log(data);
        let id = context.state.id;
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/${id}/photo-profile`, data, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // LIST colleagues
    listColleaguess(context, paging) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let limit = paging.per_page ? paging.per_page : 0;
        let page = paging.page ? paging.page : 0;
        let name = paging.name ? paging.name : "";
        axios
          .get(
            `${API_URL}/user/kolega?include=dataKota1,dataBidang&filter[nama_lengkap]=${name}&page[number]=${page}&page[size]=${limit}`,
            config
          )
          .then((res) => {
            resolve(res.data);
            const result = res.data;
            context.commit("COLLEAGUESS", result);
            if (page > 1 && name == "") {
              context.state.colleaguess.push(...result.data);
            } else {
              context.state.colleaguess = result.data;
            }
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // get all list psychologist for public
    allPsycholog(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-psikolog?include=dataKota1,dataBidang&filter[nama_lengkap]=${data.nama}&filter[bidang_id]=${data.bidang}&filter[jenis_kelamin]=${data.gender}&filter[layanan]=${data.service}&umur1=${data.from}&page[number]=${data.page}&page[size]=${data.limit}`,
            config
          )
          .then((res) => {
            resolve(res.data);
            const result = res.data;
            console.log(result);
            context.commit("ALL_PSYCHO", result);
            if (data.page > 1 && data.nama == "") {
              context.state.allPsychologForPublic.push(...result.data);
            } else {
              context.state.allPsychologForPublic = result.data;
            }
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // LIST Psyco filter by NAME AND FIELD
    findPsycholog(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-psikolog?filter[nama_lengkap]=${data}&include=dataBidang`,
            config
          )
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("FIND", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // LIST Psyco filter by city and field
    filterPsycholog(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-psikolog?filter[kota1_id]=${data.kota}&filter[bidang_id]=${data.bidang}`,
            config
          )
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("FILTERED", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // rekomendasi pertemanan psikolog by universitas id
    rekomenPsycholog(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-psikolog?filter[universitas_id]=${data}&include=dataKota1,dataKota2,dataBidang,dataUniversitas&page[number]=1&page[size]=5`,
            config
          )
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("FILTERED", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // view psycholog by username
    // viewPsycholog(context, username) {
    //   return new Promise((resolve, reject) => {
    //     let config = {
    //       // Define request header
    //       headers: {
    //         Authorization: `Bearer ${Cookies.get("token")}`
    //       }
    //     };
    //     let API_URL = process.env.VUE_APP_ENV;
    //     axios
    //       .get(`${API_URL}/user/${username}/view/username`, config)
    //       .then(res => {
    //         resolve(res.data);
    //         const data = res.data;
    //         context.commit("PSYCHOLOG_VIEW", data);
    //       })
    //       .catch(error => {
    //         const msg = error.response.data;
    //         context.commit("ERR_MSG", msg);
    //         reject(error.response.data);
    //       });
    //   });
    // }
    // view psycholog by id
    viewPsycholog(context, username) {
      return new Promise((resolve, reject) => {
        let token = Cookies.get("token");
        let role = Cookies.get("role");
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let path = "";
        if (token) {
          console.log("ada token");
          if (role != 4) {
            path = `${API_URL}/user/${username}/view`;
          } else {
            path = `${API_URL}/general/user/${username}/view`;
          }
        } else {
          console.log("ga ada token");
          path = `${API_URL}/general/user/${username}/view`;
        }
        axios
          .get(path, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("PSYCHOLOG_VIEW", data);
          })
          .catch((error) => {
            const msg = error.response.data;
            context.commit("ERR_MSG", msg);
            reject(error.response.data);
          });
      });
    },
    listFeedback(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/feedback?sort=-created_at`, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("FEEDBACK", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    giveFeedback(context, data) {
      return new Promise((resolve, reject) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/general/feedback`, data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    deleteFeedback(context, data) {
      return new Promise((resolve, reject) => {
        console.log(data);
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/feedback/${data.id}/delete`, data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    postApi(context, data) {
      return new Promise((resolve, reject) => {
        let env = context.state.API_URL;
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        axios
          .post(`${env}/${data.path}`, data.body, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getApi(context, data) {
      return new Promise((resolve, reject) => {
        let env = context.state.API_URL;
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        axios
          .get(`${env}/${data.path}`, config)
          .then((res) => {
            let data = res.data.data ? res.data.data : res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    notify({}, data) {
      Swal.fire({
        icon: data.type,
        title: data.msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
      });
    },
  },
  modules: {
    auth,
    admin,
    campus,
    psycholog,
    area,
    fields,
    feeds,
    library,
    friendship,
    agenda,
    forum,
    vacancies,
    survey,
    faq,
    notification,
    merchandise,
    aboutus,
    cust,
    quiz,
    counseling,
    moodtracker,
  },
});
