import axios from "axios";
import Cookies from "js-cookie";

const merchandise = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    listMerch: null,
    listMerchAdmin: null,
    viewMerch: null
  },
  getters: {},
  mutations: {
    MERCH(state, data) {
      state.listMerch = data;
    },
    MERCH_ADMIN(state, data) {
      state.listMerchAdmin = data;
    },
    MERCH_BYID(state, data) {
      state.agendaDetail = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //AGEnda

    //list all agenda
    listMerchandise(context) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/merchant`)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MERCH", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    listMerchandiseAdmin(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/merchant/admin`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MERCH_ADMIN", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //agenda by id
    merchandiseView(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/agenda/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MERCH_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create agenda.
    createMerc(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/merchant`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update agenda.
    updateMerch(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/merchant/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete agenda by id.
    deleteMerch(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/merchant/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};

export default merchandise;
