import axios from "axios";
import Cookies from "js-cookie";

const about = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    about: null,
    milestone: null
  },
  getters: {},
  mutations: {
    ABOUT(state, data) {
      state.about = data;
    },
    MILESTONE(state, data) {
      state.milestone = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    getAbout(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/header`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
            context.commit("ABOUT", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    updateAbout(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/header`, data, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getMilestone(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/milestone`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
            context.commit("MILESTONE", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    createMilestone(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/milestone`, data, config)
          .then(res => {
            const data = res.data;
            resolve(data);
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            reject(error);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    updateMilestone(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/milestone/update`, data, config)
          .then(res => {
            const data = res.data;
            resolve(data);
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            reject(error);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    deleteMilestone(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/milestone/delete`, data, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    }
  }
};

export default about;
