import axios from "axios";
import Cookies from "js-cookie";

const fields = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    fields: null,
    fieldsDetail: null
  },
  getters: {},
  mutations: {
    FIELDS(state, data) {
      state.fields = data;
    },
    FIELDS_BYID(state, data) {
      state.fieldsDetail = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //BIDANG

    //list all bidang
    listFields(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/all-bidang`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FIELDS", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //bidang by id
    fieldsById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/bidang/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FIELDS_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create bidang.
    createFields(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/bidang/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update bidang.
    updateFields(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/bidang/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete bidang by id.
    deleteFields(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/bidang/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};
export default fields;
