import axios from "axios";
import Cookies from "js-cookie";

const counseling = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    isVerified: null,
  },
  getters: {},
  mutations: {
    VERIFIED(state, data) {
      state.isVerified = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    // ======================================================== PSYCOLOG COUNSELING API ============YES
    // PYSCOLOG REQUEST AGAR BISA MENGGUNAKAN MENU KONSELING
    requestCounseling(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/konsultasi/request`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // CHECK STATUS REQUEST APAKAH DI SETUJUI ATAU TIDAK
    chekRequest(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/konsultasi/myrequest`, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
            context.commit("VERIFIED", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // GET TRANSACTION PSYCOLOG
    listTransactionPsy(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/konsultasi/mytransactions?include=request,sessions,public`,
            config,
          )
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // GET TRANSACTION PSYCOLOG
    trxPsyByd(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/konsultasi/mytransactions?filter[id]=${id}&include=psycholog,request,sessions,public`,
            config,
          )
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // AMBIL WAKTU DARI BACKEND
    getTime(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/konsultasi/time`, config)
          .then((res) => {
            const data = res.data.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // AMBIL WAKTU YANG TERSEDIAKU
    myAvailableTime(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/konsultasi/available/time`, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // BIKIN WAKTU AVAILABLE SENDIRI COY
    setAvailTime(context, data) {
      return new Promise((resolve) => {
        console.log(data);
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/konsultasi/available/set`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // penarikan danaaaaa

    // request tarik dana

    reqWithdrawPsy(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/withdraw/request`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // list request withdraw psikolog
    listWithdrawPsy(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/withdraw/request?page[size]=${data.limit}&page[number]=${data.page}&filter[status_withdraw]=${data.status}`,
            config,
          )
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    confirmWithdraw(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/withdraw/confirm`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // end chat
    endchat(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/konseling/chat/end`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //============================================================= PUBLIC COUNSELING API ============================================= YES
    // GET TRANSACTION PUBLICK
    listTransactionCust(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/public/konsultasi/mytransactions?include=psycholog,sessions&page[size]=${data.limit}&page[number]=${data.page}`,
            config,
          )
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // TRANSACTION BY ID
    viewTransaction(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/public/konsultasi/mytransactions?filter[id]=${id}&include=psycholog,request,sessions,refund`,
            config,
          )
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // GET REKBER
    rekber(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/konsultasi/pembayaran`, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // GET VERIFIED PSYCOLOG
    listVerifiedPsy(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let filter = `bidang=${data.bidang ? data.bidang : ""}&jenis_kelamin=${
          data.gender ? data.gender : ""
        }&agama=${data.religion ? data.religion : ""}&umur_from=${
          data.ageFrom ? data.ageFrom : ""
        }&&umur_to=${data.ageTo ? data.ageTo : ""}&filter[search]=${
          data.find ? data.find : ""
        }`;

        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/public/konsultasi/schedule?page[size]=${data.limit}&page[number]=${data.page}&include=psycholog&${filter}`,
            config,
          )
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // LIHAT PROFIL DAN SCHEDULE SEBELUM BOOKING
    viewPsycholog(context, id) {
      return new Promise((resolve, reject) => {
        let token = Cookies.get("token");
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let path = "";

        path = `${API_URL}/general/user/${id}/view?include=schedule`;
        axios
          .get(path, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            const msg = error.response.data;
            context.commit("ERR_MSG", msg);
            reject(error.response.data);
          });
      });
    },
    // MULAI BOOKING PSYCOLOG ANDA
    getPackage(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/konsultasi/package`, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // MULAI BOOKING PSYCOLOG ANDA
    letsBook(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/konsultasi/booking`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // AYO CANCLE BOOKING ANDA
    cancelBook(context) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/konsultasi/cancel`, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // GET REKBER
    evidence(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/konsultasi/booking/pay`, data, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    giveRate(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/konsultasi/rating`, data, config)
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // ============================================================= ADMIN COUNSELING API =========================yes
    // LITA TRANSACTION ADMIN
    adminTrx(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/konseling/transaction?include=psycholog,public,sessions&page[size]=${data.limit}&page[number]=${data.page}&sort=${data.sort}`,
            config,
          )
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // ADMIN MENG APPROVE/REJECT TRX USER
    isTrxValid(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/konsultasi/proof/action`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    listReqPermission(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/konseling?include=psycholog&page[size]=${data.limit}&page[number]=${data.page}&filter=${data.status}&sort=${data.sort}`,
            config,
          )
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    switchPermission(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/konseling/status`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // PENARIKAN DANA

    listWithdraw(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/withdraw/request?page[size]=${data.limit}&page[number]=${data.page}&include=psycholog&filter[status_withdraw]=${data.status}`,
            config,
          )
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    switchStatusWithdraw(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/withdraw/request/action`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // REQUEST REFUND
    listReqRefund(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/refund/request?page[size]=${data.limit}&page[number]=${data.page}`,
            config,
          )
          .then((res) => {
            const data = res.data.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    switchStatusRefund(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/refund/request/action`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // VOUCHRE MANAGE
    listVoucher(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/public/voucher?page[size]=${data.limit}&page[number]=${
              data.page
            }&filter[kode]=${data.find}&filter[id]=${
              data.id ? data.id : ""
            }&include=layanan`,
            config,
          )
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    generateVcr(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/voucher/create`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    updateVcr(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/voucher/update`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    claimVcr(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/voucher/claim`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    deleteVcr(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/voucher/delete`, data, config)
          .then((res) => {
            const data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
  },
};

export default counseling;
