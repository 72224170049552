import axios from "axios";
import Cookies from "js-cookie";

const area = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    country: null,
    countryDetail: null,
    city: null,
    generalCity: null,
    cityDetail: null,
    filteredCity: null,
    province: null,
    provinceDetail: null,
    publicProvinces: null,
    loading: false
  },

  getters: {},

  mutations: {
    LOADING(state, data) {
      state.loading = data;
    },
    COUNTRY(state, data) {
      state.country = data;
    },
    COUNTRY_BYID(state, data) {
      state.countryDetail = data;
    },

    FILTER_CITY(state, data) {
      state.filteredCity = data;
    },
    CITY(state, data) {
      state.city = data;
    },
    GENERAL_CITY(state, data) {
      state.generalCity = data;
    },

    CITY_BYID(state, data) {
      state.cityDetail = data;
    },

    PROVINCE(state, data) {
      state.province = data;
    },
    PUBLIC_PROVINCE(state, data) {
      state.publicProvinces = data;
    },

    PROVINCE_BYID(state, data) {
      state.provinceDetail = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    // COUNTRY

    //list all country
    listCountry(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/all-negara?page[number]=${data.page}&page[size]=${data.limit}&filter[nama_negara]=${data.find}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("COUNTRY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //country by id
    countryById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/negara/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("COUNTRY_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // you can create even country just by API lol.
    createCountry(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/negara/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update country.
    updateCountry(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/negara/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete university by id.
    deleteCountry(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/negara/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // Province

    // LIST Province ADMIN
    listProvince(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        console.log("data : ", data);
        axios
          .get(
            `${API_URL}/admin/all-provinsi?page[number]=${data.page}&page[size]=${data.limit}&filter[nama_provinsi]=${data.find}`,
            config
          )
          .then(res => {
            resolve(res.data);
            const result = res.data;
            context.commit("PROVINCE", result);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // LIST Province ADMIN
    listProvinceByCountry(context) {
      return new Promise(resolve => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;

        axios
          .get(`${API_URL}/admin/all-provinsi?filter[negara_id]=1`, config)
          .then(res => {
            resolve(res.data);
            const result = res.data;
            context.commit("PROVINCE", result);
            context.commit("LOADING", false);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.commit("LOADING", false);
          });
      });
    },

    // province PUBLIC
    publicProvince(context) {
      return new Promise(resolve => {
        context.state.loading = true;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/all-provinsi?filter[negara_id]=1`)
          .then(res => {
            resolve(res.data);
            const result = res.data;
            context.commit("PUBLIC_PROVINCE", result);
            context.commit("LOADING", false);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            context.commit("LOADING", false);
            console.log(error);
          });
      });
    },

    //Province by id
    provinceById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}admin/provinsi/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("PROVINCE_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create Province.
    createProvince(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/provinsi/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update Province.
    updateProvince(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/provinsi/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete Province by id.
    deleteProvince(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/provinsi/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // City

    // general city
    filterCity(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/all-kota?filter[provinsi_id]=${data}`)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("FILTER_CITY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // LIST city
    generalCity(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-kota?page[number]=${data.page}&page[size]=${data.limit}&filter[nama_kota]=${data.find}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("GENERAL_CITY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // LIST city admin
    listCity(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/all-kota?page[number]=${data.page}&page[size]=${data.per_page}&filter[nama_kota]=${data.find}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("CITY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //city by id
    CityById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}admin/kota/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("CITY_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create City.
    createCity(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/kota/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update City.
    updateCity(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/kota/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete City by id.
    deleteCity(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/kota/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};
export default area;
