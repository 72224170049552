<template>
  <div
    class="_bg-default pa-3 _20w _sidebar _100vh d-flex flex-column align-end"
  >
    <v-card
      class="radius-card mb-3 _100top _decoretion_none white"
      width="300px"
      v-if="profile"
      v-ripple="{ class: `purple--text` }"
      @click.native="toProfile"
    >
      <v-img src="../../../assets/img/meptest.jpeg" height="60px" class="pa-2">
      </v-img>
      <div class="d-flex justify-center">
        <v-avatar color="white" size="100" class="min-img">
          <img
            :src="profile.photo ? profile.photo : dummy"
            class="img-fit"
            alt="asd"
          />
        </v-avatar>
      </div>
      <div class="text-center px-10 py-2">
        <h3>{{ profile.nama_lengkap }}</h3>
        <p>{{ location ? location.nama_kota : "-" }}</p>
      </div>
    </v-card>

    <v-card class="radius-card sticky" width="300px">
      <v-list dense>
        <v-list-item active-class="blue--text" link to="/public/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="blue--text"
          link
          to="/counseling/find-psycolog"
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-quote</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Counseling</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="blue--text" link to="/user/moodtracker">
          <v-list-item-icon>
            <v-icon>mdi-emoticon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mood Tracker</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="blue--text" link to="/user-public/article/">
          <v-list-item-icon>
            <v-icon>mdi-bookshelf</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Article</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item active-class="blue--text" link to="/user/quiz">
          <v-list-item-icon>
            <v-icon>mdi-pencil-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Quiz</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="blue--text" link to="/public-user/event">
          <v-list-item-icon>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Event</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SidebarPublic",
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapState({
      profile: state => state.cust.myProfile,
      env: state => state.API_URL,
      location: state => state.cust.location,
      dummy: state => state.dummy
    })
  },
  props: ["toggleMini"],
  data() {
    return {
      sidebarMenu: true,
      banner: ""
    };
  },
  methods: {
    toProfile() {
      console.log(this.$route.path);
      if (this.$route.path !== "/public/me") {
        this.$router.push("/public/me");
      }
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
</script>

<style scoped>
._sidebar {
  position: absolute;
  height: 100%;
}
.sticky {
  position: sticky;
  top: 90px;
}
.scrollable {
  height: 300px;
  overflow: hidden;
}
.scrollable:hover {
  overflow-y: scroll;
}
.min-img {
  margin-top: -50px;
  border: 5px white solid;
}
.top {
  margin-top: 10vh;
}
._decoretion_none {
  text-decoration: none;
}
._decoretion_none:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  ._sidebar {
    display: none !important;
  }
}
@media (max-width: 576px) {
  ._sidebar {
    display: none !important;
  }
}
</style>
