import axios from "axios";
import Cookies from "js-cookie";

const survey = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    survey: null,
    my_survey: null,
    survey_byid: null,
    category: null,
    participant: null,
    participated: null,
    totalParticipant: 0,
    public_survey: null,
    public_survey_id: null
  },
  getters: {},
  mutations: {
    SURVEY(state, data) {
      state.survey = data;
    },
    PUBLIC_SURVEY(state, data) {
      state.public_survey = data;
    },
    PUBLIC_SURVEY_ID(state, data) {
      state.public_survey_id = data;
    },
    MY_SURVEY(state, data) {
      state.my_survey = data;
    },
    PARTICIPANT(state, data) {
      state.participant = data;
    },
    PARTICIPATED(state, data) {
      state.participated = data;
    },
    SURVEY_BYID(state, data) {
      let survey = null;
      if (data) {
        survey = data.data.map(el => {
          return el;
        });
      }
      state.survey_byid = survey[0];
    },
    RESET_SURVEY(state) {
      state.survey_byid = null;
    },
    CATEGORY(state, data) {
      state.category = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
    COUNT_PARTICIPANT(state, data) {
      state.totalParticipant = data;
    }
    // CHECK_ANSWER(state, data) {
    //   for(let i = 0, i < data. )
    // }
  },
  actions: {
    //survey

    //list all survey
    listSurvey(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };

        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/survey?include=dataUser`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("SURVEY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all survey
    publicSurvey(context) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/survey?include=dataUser`)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("PUBLIC_SURVEY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all survey
    publicSurveyById(context, data) {
      return new Promise(resolve => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/survey?include=dataUser,dataSoal&filter[id]=${data}`
          )
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("PUBLIC_SURVEY_ID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all my survey
    mySurvey(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = Cookies.get("id");
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/survey?include=dataUser&filter[user_id]=${id}`,
            config
          )
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("MY_SURVEY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all my survey
    myParticipant(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = Cookies.get("id");
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/survey?include=dataUser,dataJawaban&filter[user_id]=${id}`,
            config
          )
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("PARTICIPANT", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all my survey
    myParticipated(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/survey/response-by-me`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data.data;
            context.commit("PARTICIPATED", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all katogeri
    category(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/survey-kategori`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("CATEGORY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //survey by id
    getSurvey(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/survey/${data}/view?include=dataSoal,dataJawaban`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("SURVEY_BYID", data);
            // context.commit("CHECK_ANSWER", data.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create survey.
    createSurvey(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/survey/create`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update survey.
    updateSurvey(context, params) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = params.id;
        axios
          .post(`${API_URL}/user/survey/${id}/update`, params.data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete survey by id.
    deleteSurvey(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/survey/${data}/delete`, { id: data }, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // create survey.
    createQuestion(context, data) {
      return new Promise((resolve, reject) => {
        console.log(data);
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/soal/pilihan`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete soal by id.
    deleteQuestion(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/soal/${data}/delete`, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    // isi jawaban
    storeAnswer(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/general/soal-jawaban/${data.id}/create`,
            data.answer,
            config
          )
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    //total digit participant
    countTotalParticipant(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/jumlah/SurveyJawaban`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("COUNT_PARTICIPANT", data);
            // context.commit("CHECK_ANSWER", data.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    }
  }
};

export default survey;
