import axios from "axios";
import Cookies from "js-cookie";

const notification = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    notification: null,
    notificationID: null,
    category: null,
    last_page: null,
    counterNotification: null,
  },
  getters: {},
  mutations: {
    NOTIFICATION(state, data) {
      state.last_page = data.last_page;
    },
    COUNTER_NOTIFICATION(state, data) {
      let counter = data.data.filter((el) => {
        return !el.read_at;
      });
      state.counterNotification = counter.length;
    },
    FAQ_BYID(state, data) {
      state.notifID = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    //notification

    //list all notification
    listNotification(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let role = Cookies.get("role");
        let type = role == 1 || role == 3 ? "non-public" : "public";
        data["public"] = type;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/notifikasi?sort=-created_at`, data, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("NOTIFICATION", data);
            if (data.current_page > 1) {
              context.state.notification.push(...res.data.data);
            } else {
              context.state.notification = res.data.data;
            }
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all notification
    counter(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let role = Cookies.get("role");
        let type = role == 1 || role == 3 ? "non-public" : "public";
        data["public"] = type;
        axios
          .post(`${API_URL}/user/notifikasi?sort=-created_at`, data, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("COUNTER_NOTIFICATION", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //view notification
    viewNotification(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/notifikasi/${data}/view`, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //notification by id
    getFaqById(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/notification?filter[id]=${data}`, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("FAQ_BYID", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create notification.
    createFaq(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/notification/create`, data, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update notification.
    updateFaq(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/notification/${id}/update`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete notification by id.
    deleteFaq(context, data) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/notification/${data}/delete`, { id: data }, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
  },
};

export default notification;
