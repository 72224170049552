<template>
  <div class="__wait">
    <div class="d-flex align-center">
      <v-progress-circular
        :size="40"
        :width="7"
        color="white"
        indeterminate
        class="mr-3"
      ></v-progress-circular>
      <h3 class="white--text">Loading ...</h3>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.__wait {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.755), rgba(0, 0, 0, 0.727));
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
</style>
