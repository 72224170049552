<template>
  <v-row justify="center">
    <v-dialog
      v-model="drawer"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="white" dense flat>
          <div
            class="d-flex align-center mt-3 __menu _nav_menu"
            @click="toProfile"
            style="cursor: pointer"
          >
            <v-avatar size="45" class="mr-2">
              <img :src="avatar" class="img-fit" alt="" />
            </v-avatar>
            <div>
              <p class="ma-0 mb-n2">{{ firstName }}</p>
              <small>Lihat Profile Anda</small>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('swipe')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list dense>
          <v-list-item
            active-class="blue--text"
            @click="$emit('swipe')"
            link
            to="/psikolog/forum"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Forum</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="$emit('swipe')"
            active-class="blue--text"
            link
            to="/psikolog/feed"
          >
            <v-list-item-icon>
              <v-icon>mdi-comment-quote</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Feed</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group no-action prepend-icon="mdi-bookshelf">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Library</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              @click="$emit('swipe')"
              active-class="blue--text"
              link
              to="/psikolog/library"
            >
              <v-list-item-icon>
                <v-icon>mdi-book-open-blank-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>My Library</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="$emit('swipe')"
              active-class="blue--text"
              link
              to="/psikolog/public-library"
            >
              <v-list-item-icon>
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Public Library</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            @click="$emit('swipe')"
            active-class="blue--text"
            link
            to="/psikolog/list-survey"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Survey</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-group no-action prepend-icon="mdi-clipboard-list">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Survey</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="blue--text"
            link
            to="/psikolog/list-survey"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>List Survey</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item active-class="blue--text" link to="/psikolog/my-survey">
            <v-list-item-icon>
              <v-icon>mdi-book-open-blank-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>My Survey</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="blue--text"
            link
            to="/psikolog/dashboard-survey"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>participant</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

          <v-list-group no-action prepend-icon="mdi-briefcase-variant">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Vacancies</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              @click="$emit('swipe')"
              active-class="blue--text"
              link
              to="/psikolog/vacancies"
            >
              <v-list-item-icon>
                <v-icon>mdi-briefcase</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>List Vacancies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="$emit('swipe')"
              active-class="blue--text"
              link
              to="/psikolog/my-vacancies"
            >
              <v-list-item-icon>
                <v-icon>mdi-format-list-checks</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>My Vacancies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="$emit('swipe')"
              active-class="blue--text"
              link
              to="/psikolog/list-applicant"
            >
              <v-list-item-icon>
                <v-icon>mdi-human-queue</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>List Applicant</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item active-class="red--text" link @click="dialogHelp = true">
            <v-list-item-icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item active-class="red--text" link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <Help v-bind:dialogHelp="dialogHelp" @close="closeDialog" />
        <!-- <v-list dense>
          <v-list-item
            @click="$emit('swipe')"
            active-class="blue--text"
            link
            to="/psikolog/feed"
          >
            <v-list-item-icon>
              <v-icon>mdi-comment-quote</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Feed</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="$emit('swipe')"
            active-class="blue--text"
            link
            to="/psikolog/forum"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Forum</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="$emit('swipe')"
            active-class="blue--text"
            link
            to="/psikolog/library"
          >
            <v-list-item-icon>
              <v-icon>mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Library</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group no-action prepend-icon="mdi-clipboard-list-outline">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Survey</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item active-class="blue--text">
              <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>My Survey</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item active-class="blue--text">
              <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted-triangle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Parcitipant</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item active-class="blue--text">
            <v-list-item-icon>
              <v-icon>mdi-briefcase</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Vacancies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout" active-class="blue--text">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Help from "../../components/Psycholog/Modal/CustomerServiceDialog.vue";

export default {
  name: "sidebarmobile",
  props: ["drawer"],
  data() {
    return {
      dialogHelp: false
    };
  },
  components: { Help },
  computed: {
    ...mapState({
      env: state => state.API_URL,
      profile: state => state.psycholog.myProfile,
      dummy: state => state.dummy
    }),
    avatar() {
      let data = null;
      if (this.profile) {
        if (this.profile.photo_profile) {
          data = `${this.env}/upload/photo_profile/${this.profile.id}/${this.profile.photo_profile}`;
        } else {
          data = this.dummy;
        }
      }
      return data;
    },
    firstName() {
      let name = "";
      if (this.profile) {
        name = this.profile.nama_lengkap.split(" ")[0];
      }
      return name;
    }
  },
  methods: {
    closeDialog() {
      this.dialogHelp = false;
    },
    toProfile() {
      this.$router.push("/psikolog/personal");
      this.$emit("swipe");
    },
    logout() {
      this.$emit("swipe");
      this.$store.dispatch("auth/logout").then(data => {
        this.$router.go("/");
        this.alert("success", `${data.data.message}`);
      });
    },
    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg
      });
    }
  }
};
</script>

<style></style>
