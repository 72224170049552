import axios from "axios";
import Cookies from "js-cookie";

const psycholog = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    registran: null,
    myProfile: null,
    location: null,
    additionalLocation: null,
    university: null,
    field: null,
    educational: null,
    experiences: null,
    skills: null,
    listRecomendation: null,
    photo: null,
    cv: null,
    listPortfolio: null,
  },
  getters: {},
  mutations: {
    MY_PROFILE(state, data) {
      state.myProfile = data;
      let loc = data.relationships;
      for (let i = 0; i < loc.length; i++) {
        if (loc[i].kota1) {
          state.location = loc[i].kota1.data;
        }
        if (loc[i].kota2) {
          state.additionalLocation = loc[i].kota2.data;
        }
        if (loc[i].universitas) {
          state.university = loc[i].universitas.data;
        }
        if (loc[i].bidang) {
          state.field = loc[i].bidang.data;
        }
      }
    },

    PHOTO(state, data) {
      state.photo = data;
    },
    CV(state, data) {
      state.cv = data;
    },

    LIST_PORTFOLIO(state, data) {
      state.listPortfolio = data;
    },

    EDUCATIONAL(state, data) {
      state.educational = data;
    },

    EXPERIENCES(state, data) {
      state.experiences = data;
    },

    SKILLS(state, data) {
      state.skills = data;
    },

    REGISTRAN(state, data) {
      state.registran = data;
    },

    RECOMENDATION(state, data) {
      state.listRecomendation = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error.status;
    },
  },
  actions: {
    // my prfofile
    myProfile(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/my-profile?include=dataKota1,dataKota2,dataBidang,dataUniversitas`,
            config
          )
          .then((res) => {
            resolve(res.data);
            const data = res.data.data[0];
            context.commit("MY_PROFILE", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error.response);
            reject(error);
          });
      });
    },

    // my prfofile
    getPhoto(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/my-profile?fields[users]=photo_profile`, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data.data[0];
            context.commit("PHOTO", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            reject(error);
          });
      });
    },
    // edit profile
    updateProfile(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let id = data.id;
        let profile = data.data;
        console.log(profile);
        axios
          .post(`${API_URL}/user/${id}/profile`, profile, config)
          .then((res) => {
            const data = res.data;
            console.log(data);
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    updateAkun(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let profile = data.data;
        console.log(data.data);
        axios
          .post(`${API_URL}/user/update-akun`, profile, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    // # HISTORY EDUCATIONAL

    //list history educational
    listEducational(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/histori-pendidikan/${id}/list`, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("EDUCATIONAL", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create history educational
    createEducational(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };

        let API_URL = process.env.VUE_APP_ENV;

        axios
          .post(`${API_URL}/user/histori-pendidikan/create`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update history educational
    updateEducational(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let id = data.id;
        let educate = data.data;
        axios
          .post(
            `${API_URL}/user/histori-pendidikan/${id}/update`,
            educate,
            config
          )
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete history educational
    deleteEducational(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;

        axios
          .post(
            `${API_URL}/user/histori-pendidikan/${data}/delete`,
            data,
            config
          )
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // #EXPERIENCES

    //list experience
    listExperiences(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/pengalaman-kerja/${id}/view`, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("EXPERIENCES", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create experience
    createExperiences(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/pengalaman-kerja/create`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update experience
    updateExperiences(context, params) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let id = params.id;
        let data = params.data;
        axios
          .post(`${API_URL}/user/pengalaman-kerja/${id}/update`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete  experienc
    deleteExperiences(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;

        axios
          .post(
            `${API_URL}/user/pengalaman-kerja/${data.id}/delete`,
            data,
            config
          )
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // #SKILLS

    // fill data after register
    fillData(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/update`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // give recomendation to other psycholog
    giveRecomendation(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };

        let API_URL = process.env.VUE_APP_ENV;

        axios
          .post(`${API_URL}/user/rekomendasi/create`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    //list recomendation from other psychilog
    getRecomendation(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/rekomendasi/${id}/list`, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("RECOMENDATION", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getRecomendationPublic(context, id) {
      return new Promise((resolve) => {
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/rekomendasi/${id}/list`)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("RECOMENDATION", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // update recomendation to other psycholog
    updateRecomendation(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };

        let API_URL = process.env.VUE_APP_ENV;
        let id = data.id;
        let body = data.body;
        axios
          .post(`${API_URL}/user/rekomendasi/${id}/update`, body, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete  rekomendasi
    deleteRecomendation(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;

        axios
          .post(`${API_URL}/user/rekomendasi/${data.id}/delete`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    editStatusApplicant(context, item) {
      console.log(item);
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        let id = item.id;
        let data = item.data;
        axios
          .post(`${API_URL}/user/vacancies/applyers/${id}/status`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    generateCv(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/cv`, config)
          .then((res) => {
            resolve(res.data);
            const data = res.data;
            context.commit("CV", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error.response);
            reject(error);
          });
      });
    },
    deleteCv(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/cv-delete`, config)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error.response);
            reject(error);
          });
      });
    },
    createPortfolio(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };

        let API_URL = process.env.VUE_APP_ENV;

        axios
          .post(`${API_URL}/user/portfolio/create`, data, config)
          .then((res) => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    listPortfolio(context, id) {
      return new Promise((resolve) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/portfolio/${id}/list`, { id: id }, config)
          .then((res) => {
            resolve(res);
            const data = res.data;
            context.commit("LIST_PORTFOLIO", data);
          })
          .catch((error) => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
  },
};
export default psycholog;
