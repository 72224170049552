import axios from "axios";
import Cookies from "js-cookie";

const quiz = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    listQuiz: null
  },
  getters: {},
  mutations: {
    LIST(state, data) {
      state.listQuiz = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error.status;
    }
  },
  actions: {
    // my prfofile
    getList(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/quiz`, config)
          .then(res => {
            resolve(res.data.data);
            let data = res.data.data;
            context.commit("LIST", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error.response);
            reject(error);
          });
      });
    },

    getQuestion(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let id = data.id;
        let page = data.page;
        let limit = data.limit;
        axios
          .get(
            `${API_URL}/public/quiz/soal/test/${id}?page=${page}&perpage=${limit}`,
            config
          )
          .then(res => {
            const data = res.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    storeAnswer(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/public/quiz/soal/${data.id}/store-jawaban`,
            data.body,
            config
          )
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getResult(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;

        axios
          .get(`${API_URL}/public/quiz/hasil/${data}`, config)
          .then(res => {
            const data = res.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    }
  }
};
export default quiz;
