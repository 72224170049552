<template>
  <div
    class="_bg-default py-3 py-1 _20w _sidebar _100vh d-flex flex-column align-end"
  >
    <div class="_100top pr-2">
      <v-card class="radius-card mb-3 py-3 pr-1" width="310px">
        <div class="side_admin">
          <v-list dense>
            <v-list-item active-class="blue--text" link to="/admin/dashboard">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group no-action prepend-icon="mdi-account">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Psychologist</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/list-psikolog/all/list"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Psychologist List</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item active-class="blue--text" link to="/admin/request">
                <v-list-item-icon>
                  <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Request registran</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item
              active-class="blue--text"
              link
              to="/admin/verified-counseling"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Request Verified</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            </v-list-group>

            <v-list-group no-action prepend-icon="mdi-forum">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Counseling</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item active-class="blue--text" link to="/admin/trx">
                <v-list-item-icon>
                  <v-icon>mdi-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Transaction</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item active-class="blue--text" link to="/admin/voucher">
                <v-list-item-icon>
                  <v-icon>mdi-ticket-percent</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Voucher</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/verified-counseling"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Request Verified</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item active-class="blue--text" link to="/admin/withdraw">
                <v-list-item-icon>
                  <v-icon>mdi-archive-star-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Withdrawing Funds</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item active-class="blue--text" link to="/admin/refund">
                <v-list-item-icon>
                  <v-icon>mdi-credit-card-refund-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Request Refund</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              active-class="blue--text"
              link
              to="/admin/list-public-user"
            >
              <v-list-item-icon>
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>User Public</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="blue--text"
              link
              to="/admin/biro-management"
            >
              <v-list-item-icon>
                <v-icon>mdi-briefcase-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Biro</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group no-action prepend-icon="mdi-school">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Campus</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/campus-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>List University</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/majors-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-book-education</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Majors List</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group no-action prepend-icon="mdi-google-maps">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Area</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/country-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map-legend</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Negara</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                active-class="blue--text"
                link
                to="/admin/province-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Provinsi</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/city-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-city</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Kabupaten/Kota</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <!-- <v-list-item active-class="blue--text" link to="/admin/agenda">
          <v-list-item-icon>
            <v-icon>mdi-calendar-month</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Agenda</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

            <v-list-group no-action prepend-icon="mdi-calendar-month">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Agenda</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item active-class="blue--text" link to="/admin/agenda">
                <v-list-item-icon>
                  <v-icon>mdi-calendar-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Agenda</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                active-class="blue--text"
                link
                to="/admin/agenda-list"
              >
                <v-list-item-icon>
                  <v-icon>mdi-calendar-text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>List Agenda</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group no-action prepend-icon="mdi-bookshelf">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Library</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/article-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-book</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Article</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="blue--text"
                link
                to="/admin/ebook-management"
              >
                <v-list-item-icon>
                  <v-icon>mdi-book-education</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Ebook</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              active-class="blue--text"
              link
              to="/admin/forum-management"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Forum</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="blue--text"
              link
              to="/admin/merch-management"
            >
              <v-list-item-icon>
                <v-icon>mdi-store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Merchandise</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              active-class="blue--text"
              link
              to="/admin/survey-management"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Survey</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group no-action prepend-icon="mdi-briefcase-variant">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Vacancies</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="blue--text"
                link
                to="/psikolog/vacancies"
              >
                <v-list-item-icon>
                  <v-icon>mdi-briefcase</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>List Vacancies</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="blue--text"
                link
                to="/psikolog/list-applicant"
              >
                <v-list-item-icon>
                  <v-icon>mdi-human-queue</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>List Applicant</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item active-class="blue--text" link to="/management/about">
              <v-list-item-icon>
                <v-icon>mdi-information-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>About</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item active-class="blue--text" link to="/isAuth/faq">
              <v-list-item-icon>
                <v-icon>mdi-frequently-asked-questions</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>FAQ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item active-class="blue--text" link to="/admin/feedback">
              <v-list-item-icon>
                <v-icon>mdi-comment-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Feeback</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item active-class="blue--text" link to="/admin/activity">
              <v-list-item-icon>
                <v-icon>mdi-format-list-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Activity Log</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarAdmin",

  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
._sidebar {
  position: fixed;
}
.scrollable {
  height: 100%;
  overflow: hidden;
}
.scrollable:hover {
  overflow-y: scroll;
}
.min-img {
  margin-top: -50px;
  border: 2px white solid;
}
.top {
  margin-top: 10vh;
}
.side_admin {
  overflow-y: auto;
  height: 75vh;
}
.side_admin::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
.side_admin::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 10px !important;
}

/* Handle */
.side_admin::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.side_admin::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
</style>
