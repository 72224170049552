import axios from "axios";
// import { reject } from "core-js/fn/promise";
import Cookies from "js-cookie";

const campus = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    universitas: null,
    universitasDetail: null,
    majors: null,
    majorsDetail: null,
    myProfile: null
  },
  getters: {},
  mutations: {
    MY_PROFILE(state, data) {
      state.myProfile = data;
    },

    UNIVERSITAS(state, data) {
      state.universitas = data;
    },
    UNIVERSITAS_BYID(state, data) {
      state.universitasDetail = data;
    },

    MAJORS(state, data) {
      state.majors = data;
    },
    MAJORS_BYID(state, data) {
      state.majorsDetail = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    myProfile(context) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/my-profile`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data.data[0];
            context.commit("MY_PROFILE", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error.response);
            reject(error);
          });
      });
    },
    // UNIVERSITY

    //list all universitas
    listUniversitas(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/all-universitas`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("UNIVERSITAS", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //university by id
    unversitasById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/universitas/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("UNIVERSITAS_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // you can create university just by API lol.
    createUniversitas(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/universitas/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update university.
    updateUniversitas(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/universitas/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete university by id.
    deleteUniversitas(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/universitas/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // MAJORS

    // LIST MASJORS
    listMajors(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        // let universitas_id = data ? data : ''
        console.log(data);
        axios
          .get(`${API_URL}/general/all-jurusan`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("MAJORS", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //major by id
    majorsById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}admin/jurusan/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MAJORS_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create majors.
    createMajors(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/jurusan/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update majors.
    updateMajors(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/jurusan/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete majors by id.
    deleteMajors(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/jurusan/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};
export default campus;
