<template>
  <div class="home">
    <v-app-bar
      class="app__bar"
      fixed
      elevation="2"
      color="white"
      elevate-on-scroll
      v-if="role"
    >
      <section class="_nav-logo d-flex">
        <router-link to="/psikolog/home">
          <img
            src="../assets/img/logo-transparent.png"
            alt="logo"
            height="60px"
            class="mr-5 mt-1"
          />
        </router-link>
        <SearchOnNav v-if="role != 4" />
      </section>

      <div>
        <Waiting v-if="loading" />
        <Help v-bind:dialogHelp="dialogHelp" @close="closeDialog" />
        <notifications
          group="authVerification"
          class="mt-2"
          position="top center"
        />
      </div>
      <div class="user_pub d-flex justify-end" v-if="role == '4'">
        {{ counterNotification }}
        <router-link to="/psikolog/notify" class="text-decoration">
          <div class="d-flex flex-column align-center mx-3 __menu">
            <v-badge
              color="green"
              v-if="counterNotification > 0"
              overlap
              bordered
              :content="counterNotification"
            >
              <v-icon
                :size="icon"
                :color="path == 'notify' ? 'blue' : ''"
                class="mb-1"
                >mdi-bell</v-icon
              >
            </v-badge>
            <v-icon
              v-else
              :size="icon"
              :color="path == 'notify' ? 'blue' : ''"
              class="mb-1"
              >mdi-bell</v-icon
            >
            <small :class="path == 'notify' ? 'blue__' : 'black__'"
              >notifications</small
            >
          </div>
        </router-link>
        <v-menu offset-y nudge-bottom="10px" class="mt-1" v-if="provileCust">
          <template v-slot:activator="{ attrs, on }">
            <div
              v-bind="attrs"
              v-on="on"
              class="d-flex flex-column align-center mx-3 __menu _nav_menu"
            >
              <v-avatar size="40" class="mx-2 mt-2">
                <img
                  :src="provileCust.photo ? provileCust.photo : dummy"
                  class="img-fit"
                  alt=""
                />
              </v-avatar>
            </div>
          </template>
          <v-list>
            <v-list-item active-class="blue--text" link to="/public/me">
              <v-list-item-title>
                <v-btn small depressed fab><v-icon>mdi-account</v-icon></v-btn>
                Personal
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="blue--text"
              link
              to="/psikolog/portfolio/list"
            >
              <v-list-item-title>
                <v-btn small depressed fab
                  ><v-icon>mdi-file-document</v-icon></v-btn
                >
                Portofolio
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="blue--text"
              link
              :to="`/user/${id}/settings`"
            >
              <v-list-item-title>
                <v-btn small depressed fab>
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
                Settings
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-divider></v-divider>
            <v-list-item link @click="logout">
              <v-list-item-title active-class="red--text">
                <v-btn fab depressed small>
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
                Log Out
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="user_pub d-flex justify-end" v-if="role == 1">
        <v-menu offset-y nudge-bottom="10px" class="mt-1">
          <template v-slot:activator="{ attrs, on }">
            <div
              v-bind="attrs"
              v-on="on"
              class="d-flex flex-column align-center mx-3 __menu _nav_menu"
            >
              <v-avatar size="40" class="mx-2 mt-2">
                <img :src="avatar" class="img-fit" alt="" />
              </v-avatar>
            </div>
          </template>
          <v-list>
            <v-list-item link @click="logout">
              <v-list-item-title active-class="red--text">
                <v-btn fab depressed small>
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
                Log Out
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="__nav_app" v-if="role == 3">
        <router-link to="/psikolog/forum" class="text-decoration _switch_menu">
          <div class="d-flex flex-column align-center mx-3 __menu">
            <v-icon
              :size="icon"
              :color="path == 'forum' ? 'blue' : ''"
              class="mb-1"
              >mdi-home</v-icon
            >
          </div>
        </router-link>

        <router-link to="/psikolog/koneksi" class="text-decoration">
          <div class="d-flex flex-column align-center mx-3 __menu">
            <v-badge
              color="green"
              v-if="counterReq > 0"
              overlap
              bordered
              :content="counterReq"
            >
              <v-icon
                :size="icon"
                :color="path == 'Connection' ? 'blue' : ''"
                class="mb-1"
                >mdi-account-convert</v-icon
              >
            </v-badge>
            <v-icon
              :size="icon"
              :color="path == 'Connection' ? 'blue' : ''"
              class="mb-1"
              v-else
              >mdi-account-convert</v-icon
            >
            <small :class="path == 'Connection' ? 'blue__' : 'black__'"
              >connection</small
            >
          </div>
        </router-link>

        <router-link to="/psikolog/kolega" class="text-decoration">
          <div class="d-flex flex-column align-center mx-3 __menu">
            <v-icon
              :size="icon"
              :color="path == 'Colleaguess' ? 'blue' : ''"
              class="mb-1"
              >mdi-briefcase-account</v-icon
            >
            <small :class="path == 'Colleaguess' ? 'blue__' : 'black__'"
              >colleaguess</small
            >
          </div>
        </router-link>

        <router-link to="/psikolog/notify" class="text-decoration">
          <div class="d-flex flex-column align-center mx-3 __menu">
            <v-badge
              color="green"
              v-if="counterNotification > 0"
              overlap
              bordered
              :content="counterNotification"
            >
              <v-icon
                :size="icon"
                :color="path == 'notify' ? 'blue' : ''"
                class="mb-1"
                >mdi-bell</v-icon
              >
            </v-badge>
            <v-icon
              v-else
              :size="icon"
              :color="path == 'notify' ? 'blue' : ''"
              class="mb-1"
              >mdi-bell</v-icon
            >
            <small :class="path == 'notify' ? 'blue__' : 'black__'"
              >notifications</small
            >
          </div>
        </router-link>

        <div class="d-flex flex-column align-center mx-3 __menu _switch_menu">
          <v-icon @click="drawer = true" :size="icon" class="mb-1"
            >mdi-menu</v-icon
          >
        </div>

        <v-menu offset-y nudge-bottom="10px">
          <template v-slot:activator="{ attrs, on }">
            <div
              v-bind="attrs"
              v-on="on"
              class="d-flex flex-column align-center mx-3 __menu _nav_menu"
            >
              <v-avatar size="40" class="mx-2">
                <img :src="avatar" class="img-fit" alt="" />
              </v-avatar>
            </div>
          </template>
          <v-list>
            <v-list-item active-class="blue--text" link to="/psikolog/personal">
              <v-list-item-title>
                <v-btn small depressed fab><v-icon>mdi-account</v-icon></v-btn>
                Personal
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="blue--text"
              link
              to="/psikolog/portfolio/list"
            >
              <v-list-item-title>
                <v-btn small depressed fab
                  ><v-icon>mdi-file-document</v-icon></v-btn
                >
                Portofolio
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="blue--text"
              link
              :to="`/user/${id}/settings`"
            >
              <v-list-item-title>
                <v-btn small depressed fab>
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
                Settings
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item
              active-class="blue--text"
              link
              to="/psikolog/personal"
              v-if="role == 1"
            >
              <v-list-item-title>
                <v-btn small depressed fab>
                  <v-icon>mdi-account</v-icon>
                </v-btn>
                Profile
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item link @click="logout">
              <v-list-item-title active-class="red--text">
                <v-btn fab depressed small>
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
                Log Out
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider vertical class="_nav_menu"></v-divider>
        <v-btn text color="blue" class="_nav_menu" @click="dialogHelp = true"
          ><v-icon class="mr-2">mdi-help-circle</v-icon> Help</v-btn
        >
      </div>
    </v-app-bar>
    <SidebarMobile
      v-if="role == 3"
      v-bind:drawer="drawer"
      @swipe="drawer = false"
    />
    <sidebar-public v-if="role == 4" />
    <SidebarPsycholog v-if="role == 3" />
    <SidebarCampus v-if="role == 2" />
    <SidebarAdmin v-if="role == 1" />
    <div v-if="role == 3">
      <div class="d-flex _bg-default" v-if="profile">
        <router-view></router-view>
      </div>
    </div>
    <div class="d-flex" v-else>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SidebarCampus from "../components/General/Sidebar/sidebarCampus.vue";
import SidebarAdmin from "../components/General/Sidebar/sidebarAdmin.vue";
import SidebarPsycholog from "../components/General/Sidebar/sidebarPsycholog.vue";
import SearchOnNav from "../components/Psycholog/searchOnNav.vue";
import SidebarMobile from "../views/Psycholog/sidebarMobile.vue";
import Waiting from "../components/_base/Modal/waiting.vue";
import Help from "../components/Psycholog/Modal/CustomerServiceDialog.vue";
import SidebarPublic from "../components/General/Sidebar/sidebarPublic.vue";

export default {
  name: "Home",
  components: {
    SidebarPsycholog,
    SidebarAdmin,
    Waiting,
    SearchOnNav,
    SidebarMobile,
    SidebarCampus,
    Help,
    SidebarPublic,
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      env: (state) => state.API_URL,
      id: (state) => state.id,
      profile: (state) => state.psycholog.myProfile,
      provileCust: (state) => state.cust.myProfile,
      errMsg: (state) => state.psycholog.errMsg,
      counterReq: (state) => state.friendship.counterRequest,
      counterNotification: (state) => state.notification.counterNotification,
      dummy: (state) => state.dummy,
    }),
    avatar() {
      let data = null;
      if (this.profile) {
        if (this.profile.photo_profile) {
          data = `${this.env}/upload/photo_profile/${this.profile.id}/${this.profile.photo_profile}`;
        } else {
          data = `${this.env}/images/logo.png`;
        }
      } else if (this.role == 2) {
        data = `${this.env}/images/logo.png`;
      } else if (this.role == 1) {
        data = `${this.env}/images/logo.png`;
      }
      return data;
    },
    icon() {
      let data = null;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return (data = 25);
        case "sm":
          return (data = 25);
        case "md":
          return (data = 25);
        case "lg":
          return (data = 25);
        case "xl":
          return (data = 25);
      }
      return data;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.getPath();
    },
  },

  data() {
    return {
      loading: false,
      path: "",
      drawer: false,
      dialogHelp: false,
    };
  },
  async created() {
    window.addEventListener("scroll", () => {
      if (!this.role) {
        let nav = document.querySelector("._nav");
        nav.classList.toggle("_scroll", window.scrollY > 0);
      }
    });
    if (this.role == "3" || this.role == "4") {
      await this.fetch();
    }
    this.getPath();
    if (this.role == "3" || this.role == "4") {
      this.counterNotif();
    }
    this.fetchRequest();
  },
  methods: {
    fetch() {
      this.loading = true;
      let endpoint =
        this.role == "3" ? "psycholog/myProfile" : "cust/myProfile";
      this.$store
        .dispatch(endpoint)
        .then((data) => {
          if (data.role_id == "4") {
            if (data.kota_id) {
              this.$store.dispatch("cust/getDomisili", data.kota_id);
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.logout();
          this.loading = false;
        });
    },

    closeDialog() {
      this.dialogHelp = false;
    },

    counterNotif() {
      this.$store
        .dispatch("notification/counter", {
          page: 1,
          per_page: 100,
        })
        .then((data) => {
          console.log(data);
        });
    },

    fetchRequest() {
      if (this.role == 3) {
        this.$store
          .dispatch("friendship/listRequest")
          .then(() => {
            console.log("ok");
          })
          .catch(() => {
            this.logout();
          });
      }
    },

    logout() {
      console.log("asas");
      this.$store.dispatch("auth/logout").then((data) => {
        this.alert("success", `${data.data.message}`);
        this.$router.go("/");
      });
    },
    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
      });
    },
    getPath() {
      this.path = this.$route.name;
    },
  },
};
</script>
<style scoped>
.__nav_app {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
._switch_menu {
  display: none !important;
}
.__menu {
  cursor: pointer;
}
.blue__ {
  color: blue !important;
}
.black__ {
  color: black !important;
}
.app__bar {
  /* display: flex;
  justify-content: space-between; */
  height: 11vh !important;
}
.side_hide {
  overflow: hidden;
}
.user_pub {
  width: 40%;
}
@media (max-width: 576px) {
  .__nav_app {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  ._nav-logo {
    display: none !important;
  }
  ._nav_menu {
    display: none !important;
    overflow: hidden;
  }
  ._switch_menu {
    display: block !important;
  }
  .app__bar {
    height: 10vh !important;
  }
  .blue__ {
    display: none;
  }
  .black__ {
    display: none;
  }
}
@media (max-width: 768px) {
  .__nav_app {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  ._nav-logo {
    display: none !important;
  }
  ._nav_menu {
    display: none !important;
    overflow: hidden;
  }
  ._switch_menu {
    display: block !important;
  }
  .app__bar {
    height: 10vh !important;
  }
  .blue__ {
    display: none;
  }
  .black__ {
    display: none;
  }
}
</style>
