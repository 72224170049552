import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Home,
    meta: { requiresVisitor: true },
    children: [
      //ROUTER ADMIN
      {
        path: "/admin/dashboard",
        name: "dashboard admin",
        component: () => import("../views/Admin/DashboardAdmin.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/public/home",
        name: "dashboard public",
        component: () => import("../views/BATCH3/Home/publicHome.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/list-psikolog/:type/:value",
        name: "list psikolog",
        component: () => import("../views/Admin/ListPsychologist.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/import-psy",
        name: "import psycolog",
        component: () => import("../views/Admin/importPsy.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/list-public-user",
        name: "list publicUser",
        component: () => import("../views/Admin/listUserPublic.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/campus-management",
        name: "Campus management",
        component: () => import("../views/Admin/CampusManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/biro-management",
        name: "Biro management",
        component: () => import("../views/Admin/biroManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/majors-management",
        name: "Major management",
        component: () => import("../views/Admin/majorManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/forum-management",
        name: "forum management",
        component: () => import("../views/Admin/forumManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/create/Article",
        name: "admin create artilce",
        component: () => import("../components/Admin/adminCreateArticle.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/create/ebook",
        name: "admin create ebook",
        component: () => import("../components/Admin/adminCreateEbook.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/merch-management",
        name: "merchandise",
        component: () => import("../views/Admin/Merchandise.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/agenda",
        name: "agenda",
        component: () => import("../views/Admin/agenda.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/agenda-list",
        name: "agenda-list",
        component: () => import("../views/Admin/listAgenda.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/agenda-view/:id",
        name: "agenda-view",
        component: () => import("../views/Admin/viewAgenda.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/agenda-edit/:id",
        name: "agenda-edit",
        component: () => import("../views/Admin/editAgenda.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/agenda-participant/:id/:status",
        name: "agenda-participant",
        component: () => import("../views/Admin/listParticipantAgenda.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/activity",
        name: "logactivity",
        component: () => import("../views/Admin/logActivity.vue"),
        meta: { requiresAuth: true },
      },
      // area
      {
        path: "/admin/country-management",
        name: "Country management",
        component: () => import("../views/Admin/countryManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/province-management",
        name: "Province management",
        component: () => import("../views/Admin/provinceManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/city-management",
        name: "city management",
        component: () => import("../views/Admin/cityManagement.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/admin/request",
        name: "request",
        component: () => import("../views/Admin/Request.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/verified-counseling",
        name: "verified Psy",
        component: () => import("../views/Admin/requestVerified.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/admin/article-management",
        name: "articleManagement",
        component: () => import("../components/Admin/articleManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/ebook-management",
        name: "ebookManagement",
        component: () => import("../components/Admin/ebookManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/survey-management",
        name: "surveyManagement",
        component: () => import("../views/Admin/surveyManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/create-vacancies",
        name: "vacancies",
        component: () => import("../components/Psycholog/createVacancies.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/feedback",
        name: "feedback",
        component: () => import("../views/Admin/listFeedback.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/page-not-found",
        name: "page-not-found",
        component: () => import("../views/Psycholog/deletedPageInfo.vue"),
        meta: { requiresAuth: true },
      },

      // ROUTER PUBLIK
      {
        path: "/",
        name: "landing page",
        component: () => import("../views/Public/MainPage.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/public/cari-psikolog",
        name: "cari psikolog",
        component: () => import("../views/Public/findPsycholog.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/public/konseling",
        name: "Conseling",
        component: () => import("../views/Public/Conseling.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/article/:id/view",
        name: "viewArticle",
        component: () => import("../views/General/viewArticle.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "public/article/",
        name: "allArticle",
        component: () => import("../views/General/allArticle.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/user-public/article/",
        name: "userallArticle",
        component: () => import("../views/BATCH3/public_user/articleUser"),
        meta: { requiresAuth: true },
      },
      {
        path: "public/ebook/",
        name: "allEookpublic",
        component: () => import("../views/General/allEbook.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "public/merchant/",
        name: "allMerchant",
        component: () => import("../views/General/allMerchant.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "public/cari-tahu/",
        name: "cari tau",
        component: () => import("../views/Public/findOut.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "public/library/",
        name: "public-library",
        component: () => import("../views/General/allEbook.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: ":visible/article/:id/view",
        name: "publicArticle",
        component: () => import("../views/General/viewArticle.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/ebook/:id/:visible",
        name: "viewEbook",
        component: () => import("../views/General/viewEbook.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "public/ebook/:id/view",
        name: "publicebook",
        component: () => import("../views/General/viewEbook.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "public/survey/list",
        name: "publicSurvey",
        component: () => import("../views/General/publicSurvey.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "survey/:id/view",
        name: "viewSurvey",
        component: () => import("../views/General/viewSurvey.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "survey/:id/participated",
        name: "viewParticipated",
        component: () => import("../views/General/participatedDetail.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "public/survey/:id/view",
        name: "viewSurveyPublic",
        component: () => import("../views/General/viewSurveyPublic.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/pam/:username",
        name: "viewPsycholog",
        component: () => import("../views/General/viewPsycholog.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/about/",
        name: "about us",
        component: () => import("../views/General/AboutUs.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/management/about",
        name: "aboutus",
        component: () => import("../components/Admin/managementAboutus.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/isAuth/faq",
        name: "faq",
        component: () => import("../views/General/FAQ.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/noAuth/faq",
        name: "faqPublic",
        component: () => import("../views/General/FAQ.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/noAuth/privacy",
        name: "Privacy",
        component: () => import("../views/General/Privacy.vue"),
        meta: { requiresVisitor: true },
      },
      //ROUTER PSYCHOLOG
      {
        path: "/psikolog/home",
        name: "home psy",
        component: () => import("../views/Psycholog/psychologHome.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/feed",
        name: "feed",
        component: () => import("../views/Psycholog/Feed.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/event",
        name: "evtPsy",
        component: () => import("../views/Psycholog/eventPsyco.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/public-user/event",
        name: "publicUserEvent",
        component: () =>
          import("../views/BATCH3/public_user/agenda/pageAgenda.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/feed/:id",
        name: "viewFeed",
        component: () => import("../views/General/viewFeed.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/notify",
        name: "notify",
        component: () => import("../views/Psycholog/notification.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/personal",
        name: "Personal",
        component: () => import("../views/Psycholog/Personal.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/portfolio/list",
        name: "Portfolio View",
        component: () => import("../views/Psycholog/portofolio.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/portfolio/create",
        name: "Portfolio Create",
        component: () => import("../components/Psycholog/createPortfolio.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "",
        name: "dashboard survey",
        component: () => import("../views/Psycholog/dashboardSurvey.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "/psikolog/my-survey",
            name: "allSurvey",
            component: () => import("../views/Psycholog/mySurvey.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "/psikolog/list-survey",
            name: "listsurvey",
            component: () => import("../views/Admin/listSurvey.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "/psikolog/participated",
            name: "Partisipated",
            component: () => import("../views/Psycholog/participated.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "/psikolog/participant",
            name: "Partisipan",
            component: () => import("../views/Psycholog/participant.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "/user/:id/settings",
        name: "Setting",
        component: () => import("../views/Psycholog/Settings.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/kolega",
        name: "Colleaguess",
        component: () => import("../views/Psycholog/Colleaguess.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/koneksi",
        name: "Connection",
        component: () => import("../views/Psycholog/Connection.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/library",
        name: "library",
        component: () => import("../views/Psycholog/Library.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/public-library",
        name: "publicLibrary",
        component: () => import("../views/Psycholog/LibraryPublic.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/forum",
        name: "forum",
        component: () => import("../views/Psycholog/Forum.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/vacancies",
        name: "listVacan",
        component: () => import("../views/Psycholog/Vacancies.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/my-vacancies",
        name: "myVacan",
        component: () => import("../views/Psycholog/myVacancies.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/vacancies/:id",
        name: "vacanciesDetail",
        component: () => import("../views/Psycholog/vacanciesDetail.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/vacancies/:id/edit",
        name: "vacanciesEdit",
        component: () => import("../views/Psycholog/vacanciesEdit.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/thread/:id",
        name: "Thread",
        component: () => import("../views/General/viewThread.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/my-feed",
        name: "myfeed",
        component: () => import("../views/Psycholog/myFeed.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/my-forum",
        name: "myforum",
        component: () => import("../views/Psycholog/myForum.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/my-application",
        name: "myapplication",
        component: () => import("../views/Psycholog/myApplication.vue"),
        meta: { requiresAuth: true },
      },
      // {
      //   path: "/psikolog/my-survey",
      //   name: "my survey",
      //   component: () => import("../views/Psycholog/mySurvey.vue"),
      //   meta: { requiresAuth: true },
      // },
      {
        path: "/psikolog/survey/thanks",
        name: "thanksSurvey",
        component: () => import("../views/Psycholog/SurveyThanks.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/general/survey/thanks",
        name: "thanksSurveypublic",
        component: () => import("../views/Psycholog/SurveyThanks.vue"),
        meta: { requiresVisitor: true },
      },
      {
        path: "/psikolog/create-survey",
        name: "createsurvey",
        component: () => import("../views/Psycholog/creteSurvey.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/survey/:id/update",
        name: "updatesurvey",
        component: () => import("../views/Psycholog/updateSurvey.vue"),
        meta: { requiresAuth: true },
      },
      // {
      //   path: "/psikolog/list-survey",
      //   name: "listsurvey",
      //   component: () => import("../views/Admin/listSurvey.vue"),
      //   meta: { requiresAuth: true },
      // },
      {
        path: "/psikolog/list-applicant",
        name: "listapplicant",
        component: () => import("../views/General/listApplicant.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/applicant/:username",
        name: "viewApplicant",
        component: () => import("../views/General/viewApplicant.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      // BATCH 3 ==============================================
      //  profile user public
      {
        path: "/public/me",
        name: "homeUserPublic",
        component: () =>
          import("../views/BATCH3/public_user/profilePublic.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/public/dashboard-counseling",
        name: "dashboard public counseling",
        component: () =>
          import("../views/BATCH3/Counseling/public/dashboardCounseling.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/counseling",
        name: "counseling db",
        component: () =>
          import(
            "../views/BATCH3/Counseling/psycholog/dashboardCounseling.vue"
          ),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/psikolog/balance",
        name: "balance",
        component: () =>
          import("../views/BATCH3/Counseling/psycholog/myBalance.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/user/quiz",
        name: "list quiz",
        component: () => import("../views/BATCH3/Quiz/listQuiz"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/user/moodtracker",
        name: "moodtracker",
        component: () =>
          import("../views/BATCH3/moodTracker/mainMoodTracker.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/quiz/gad/:id",
        name: "quiz gad",
        component: () => import("../views/BATCH3/Quiz/alatTes/Gad.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/counseling/find-psycolog",
        name: "find spy",
        component: () =>
          import("../components/Counseling/Public/listVerifiedPsy.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/counseling/prepare-book/:id",
        name: "prepare bok",
        component: () =>
          import("../components/Counseling/Public/prepareBook.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/counseling/detail-transaction/:id",
        name: "view Transaction",
        component: () =>
          import("../components/Counseling/Public/transactionByid.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/trx",
        name: "admin Transaction",
        component: () => import("../views/Admin/transactionAdmin.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/withdraw",
        name: "admin wthdraw",
        component: () => import("../views/Admin/tarikDanaAdmin.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/refund",
        name: "admin refund",
        component: () => import("../views/Admin/reqRefund.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/voucher",
        name: "adminvOUCHER",
        component: () => import("../views/Admin/manageVoucher.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/create-voucher",
        name: "createvOUCHER",
        component: () => import("../views/Admin/createVoucher.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/update-voucher/:id",
        name: "updatevOUCHER",
        component: () => import("../views/Admin/updateVoucher.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/update-psycolog/:id",
        name: "admin edit psikoloh",
        component: () => import("../views/Admin/adminEditPsikolog.vue"),
        props: true,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/chat/:id",
    name: "chat",
    component: () => import("../views/BATCH3/chat/chatRoom.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/history-chat/:id/:sesi",
    name: "history-chat",
    component: () => import("../views/BATCH3/chat/historyCounseling"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/done-counseling/:id",
    name: "done",
    component: () => import("../views/BATCH3/chat/afterCounseling.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/:username",
    name: "viewPsychologPublic",
    component: () => import("../views/Public/PsychologIsntAuth.vue"),
    props: true,
    meta: { requiresVisitor: true },
  },
  {
    path: "/psikolog/fill-data",
    name: "Fill data",
    component: () => import("../views/Psycholog/fillData.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/psikolog/waiting",
    name: "Waiting Page",
    component: () => import("../views/Psycholog/waitingPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () => import("../views/General/pageNotFound.vue"),
  },
  {
    path: "/auth/:route",
    name: "Login",
    component: () => import("../views/Auth/Gate.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/waiting/:email",
    name: "reg",
    component: () => import("../views/Auth/registerSuccess.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/dummy/x",
    name: "regs",
    component: () => import("../views/cobain.vue"),
    meta: { requiresVisitor: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUser) {
      if (to.name == "viewPsycholog") {
        console.log(to);
        next({
          path: `/${to.params.username}`,
        });
      } else {
        next({
          path: "/",
        });
      }
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.getUser && store.state.role == 1) {
      next({
        path: "/admin/dashboard",
      });
    } else if (store.getters.getUser && store.state.role == 2) {
      next({
        path: "/admin/dashboard",
      });
    } else if (store.getters.getUser && store.state.role == 3) {
      if (store.state.status == 0) {
        next({
          path: "/psikolog/fill-data",
        });
      } else if (store.state.status == 1) {
        next({
          path: "/psikolog/waiting",
        });
      } else {
        next({
          path: "/psikolog/home",
        });
      }
    } else if (store.getters.getUser && store.state.role == 4) {
      next({
        path: "/public/home",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
