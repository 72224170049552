import axios from "axios";
import Cookies from "js-cookie";

const forum = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    forum: null,
    thread: null,
    mod: null,
    category: null,
    hot_thread: null,
    my_forum: null,
    last_page_forum: null,
    forum_for_admin: null
  },
  getters: {},
  mutations: {
    FORUM(state, data) {
      state.last_page_forum = data.meta.last_page;
    },
    MY_FORUM(state, data) {
      state.my_forum = data;
    },
    HOT_THREAD(state, data) {
      state.hot_thread = data;
    },
    GET_THREAD(state, data) {
      state.thread = data;
    },
    GET_MOD(state, data) {
      state.mod = data;
    },
    CATEGORY(state, data) {
      state.category = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //forum
    //list all forum
    myForum(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = Cookies.get("id");
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/forum?include=dataComment,dataUser,likers&sort=-created_at&filter[user_id]=${id}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MY_FORUM", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all forum
    listForum(context, paging) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        console.log(paging);
        let API_URL = process.env.VUE_APP_ENV;
        let limit = paging.per_page;
        let page = paging.page;
        axios
          .get(
            `${API_URL}/user/forum?include=dataComment,dataUser,likers&sort=-created_at&page[number]=${page}&page[size]=${limit}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FORUM", data);
            if (page > 1) {
              context.state.forum.push(...data.data);
            } else {
              context.state.forum = data.data;
            }
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
          });
      });
    },
    //list all forum
    getForum(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/forum?include=dataComment,dataUser,likers&sort=-created_at`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FORUM", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all HT
    hotThread(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/forum?include=dataComment,dataUser,likers&sort=-views&page[number]=${data.page}&page[size]=${data.limit}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("HOT_THREAD", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    //list all forum
    category(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/forum-kategori`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("CATEGORY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //forum by id
    getThread(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/forum/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("GET_THREAD", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create forum.
    createForum(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/forum/create`, data, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update forum.
    updateForum(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/user/forum/${id}/update`, data.body, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete forum by id.
    deleteForum(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/forum/${data}/delete`, { id: data }, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // like forum.
    likeForum(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/forum/${id}/like`, { id: id }, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    // unlike forum.
    unlikeForum(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/forum/${id}/unlike`, { id: id }, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    comment(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id;
        let content = { content: data.content };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/forum/comment/${id}/create`, content, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    updateComment(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id;
        let content = { content: data.content };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/forum/comment/${id}/update`, content, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    deleteComment(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };

        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/user/forum/comment/${id}/delete`,
            { id: id },
            config
          )
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    //forum by id
    listModerator(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/moderator/${data}/list?include=dataUser`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("GET_MOD", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // assign moderator.
    assignMod(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id_user;
        let category = data.category;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/admin/moderator/${id}/assign`,
            { nama_kategori: category },
            config
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    // unassign moderator.
    unassignMod(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id_user;
        let category = data.category;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/admin/moderator/${id}/unassign`,
            { nama_kategori: category },
            config
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // assign moderator.
    closeThread(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id_forum;
        let msg = data.msg;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/moderator/forum/${id}/status`,
            { alasan: msg },
            config
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};

export default forum;
