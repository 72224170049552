import axios from "axios";
import Cookies from "js-cookie";

const library = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    article: null,
    articleAdmin: null,
    articleAdminBlock: null,
    my_article: null,
    articleDetail: null,
    articleComment: null,
    e_book: null,
    public_ebook: null,
    public_ebook_admin: null,
    public_ebook_admin_block: null,
    my_e_book: null,
    e_bookDetail: null,
    loading: false,
    category: null,
    ebookComment: null
  },
  getters: {},
  mutations: {
    ARTILCLES(state, data) {
      state.article = data;
    },

    ARTILCLES_ADMIN(state, data) {
      state.articleAdmin = data;
    },

    ARTILCLES_ADMIN_BLOCK(state, data) {
      state.articleAdminBlock = data;
    },

    ARTICLE_BYID(state, data) {
      state.articleDetail = data;
    },

    MY_ARTILCLES(state, data) {
      state.my_article = data;
    },

    ARTICLE_COMMENT(state, data) {
      state.articleComment = data;
    },

    EBOOK_COMMENT(state, data) {
      state.ebookComment = data;
    },

    EBOOKS(state, data) {
      state.e_book = data;
    },

    PUBLIC_EBOOK(state, data) {
      state.public_ebook = data;
    },

    PUBLIC_EBOOK_ADMIN(state, data) {
      state.public_ebook_admin = data;
    },

    EBOOK_ADMIN_BLOCK(state, data) {
      state.public_ebook_admin_block = data;
    },

    EBOOK_BYID(state, data) {
      state.e_bookDetail = data;
    },

    MY_EBOOKS(state, data) {
      state.my_e_book = data;
    },

    CATEGROY(state, data) {
      state.category = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //list my article
    publicArticle(context, data) {
      return new Promise(resolve => {
        context.state.loading = true;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-artikel?page[number]=${data.page}&page[size]=${data.limit}&include=dataUser&filter[judul]=${data.find}`
          )
          .then(res => {
            const data = res.data;
            resolve(data);
            context.commit("ARTILCLES", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    //article admin
    adminArticle(context, data) {
      return new Promise(resolve => {
        context.state.loading = true;
        let API_URL = process.env.VUE_APP_ENV;
        console.log(data);
        axios
          .get(
            `${API_URL}/general/all-artikel-admin?page[number]=${data.page}&page[size]=${data.limit}&include=dataUser&filter[judul]=${data.find}`
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("ARTILCLES_ADMIN", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    //article admin block
    adminArticleBlock(context, data) {
      return new Promise(resolve => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/admin/artikel/list-blokir-admin?dataUser`,
            config,
            data
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("ARTILCLES_ADMIN_BLOCK", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    //list my article
    MyArticle(context) {
      return new Promise(resolve => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/artikel`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MY_ARTILCLES", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    // article by id
    articleById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/artikel/${data}/view?include=dataUser,likers`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("ARTICLE_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // article by id
    articleByIdPublic(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/artikel/${data}/view?include=dataUser,likers`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("ARTICLE_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create article.
    createArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/artikel/create`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    // update article.
    updateArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        console.log(data.data);
        axios
          .post(`${API_URL}/user/artikel/${data.id}/update`, data.data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete article by id.
    deleteArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/artikel/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
            console.log(config);
            reject(error.response.data);
          });
      });
    },

    //=== E-book

    //list public e-book
    publicEbook(context, data) {
      return new Promise(resolve => {
        context.state.loading = true;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-ebook?include=likers&page[number]=${data.page}&page[size]=${data.limit}&filter[ebook]=${data.find}`
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("PUBLIC_EBOOK", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    publicEbookAdmin(context, data) {
      return new Promise(resolve => {
        context.state.loading = true;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/general/all-ebook-admin?include=likers&page[number]=${data.page}&page[size]=${data.limit}&filter[ebook]=${data.find}`
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("PUBLIC_EBOOK_ADMIN", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    //ebook admin block
    adminEbookBlock(context, data) {
      return new Promise(resolve => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/admin/ebook/list-blokir-admin`, config, data)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("EBOOK_ADMIN_BLOCK", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    //list my e-book
    MyEbook(context) {
      return new Promise(resolve => {
        context.state.loading = true;
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/ebook`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MY_EBOOKS", data);
            context.state.loading = false;
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
            context.state.loading = false;
          });
      });
    },

    // e-book by id
    ebookById(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/general/ebook/${data}/view?include=likers`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("EBOOK_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    // e-book by id
    viewPrivateEbook(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/ebook/${data}/view?include=likers`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("EBOOK_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create e-book
    createEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/ebook/create`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(error);
            context.commit("ERR_MSG", err);
          });
      });
    },

    // update e-book.
    updateEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        console.log(data.data);
        axios
          .post(`${API_URL}/user/ebook/${data.id}/update`, data.data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete e-book by id.
    deleteEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/ebook/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },

    // list category
    listCategory(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/artikel-kategori`, data, config)
          .then(res => {
            resolve(res.data);
            context.commit("CATEGROY", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    createCommentArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/comment/create/Artikel`, data, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    updateCommentArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let id = data.id;
        let body = {
          content: data.content
        };
        axios
          .post(`${API_URL}/user/comment/${id}/update`, body, config)
          .then(res => {
            context.commit("SOLVE_MSG", res.data);
            resolve(res);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    deleteCommentArticle(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };

        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/comment/${id}/delete`, { id: id }, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    listCommentArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/comment/${data}/view/Artikel?include=dataUser&sort=-created_at`,
            config
          )
          .then(res => {
            resolve(res.data);
            context.commit("ARTICLE_COMMENT", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    createCommentEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/comment/create/Ebook`, data, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    listCommentEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/comment/${data}/view/Ebook?include=dataUser&sort=-created_at`,
            config
          )
          .then(res => {
            resolve(res.data);
            context.commit("EBOOK_COMMENT", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    likeEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let body = {
          id: data
        };
        axios
          .post(`${API_URL}/user/ebook/like`, body, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    unlikeEbook(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let body = {
          id: data
        };
        axios
          .post(`${API_URL}/user/ebook/unlike`, body, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    likeArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let body = {
          id: data
        };
        axios
          .post(`${API_URL}/user/artikel/like`, body, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    unlikeArticle(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let body = {
          id: data
        };
        axios
          .post(`${API_URL}/user/artikel/unlike`, body, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    statusBlockArticle(context, request) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let data = {
          id: request.item.id,
          status_blokir: request.status_blokir,
          alasan_blokir: request.alasan_blokir
        };
        axios
          .post(`${API_URL}/admin/artikel/status-blokir`, data, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    },

    statusBlockEbook(context, request) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let data = {
          id: request.item.id,
          status_blokir: request.status_blokir,
          alasan_blokir: request.alasan_blokir
        };
        axios
          .post(`${API_URL}/admin/ebook/status-blokir`, data, config)
          .then(res => {
            resolve(res.data);
            context.commit("SOLVE_MSG", res.data);
          })
          .catch(error => {
            let err = error.data.response;
            reject(err);
            context.commit("ERR_MSG", err);
          });
      });
    }
  }
};
export default library;
