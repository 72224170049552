import axios from "axios";
import Cookies from "js-cookie";

const moodtracker = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: ""
  },
  getters: {},
  mutations: {
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    getMood(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/mood-tracker/mood`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getFeel(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/mood-tracker/feel`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getDoing(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/mood-tracker/doing`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getWhere(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/mood-tracker/where`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    getWith(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/mood-tracker/with`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    getHistory(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/public/mood-tracker/history?history=${data.time}&page[size]=${data.limit}&page[number]=${data.page}`,
            config
          )
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    saveMoodToday(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/mood-tracker`, data, config)
          .then(res => {
            const data = res.data;
            resolve(data);
          })
          .catch(error => {
            reject(error);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    createDo(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/mood-tracker/doing`, data, config)
          .then(res => {
            const data = res.data;
            resolve(data);
          })
          .catch(error => {
            reject(error);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    makeChart(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/mood-tracker`, config)
          .then(res => {
            const data = res.data.data;
            resolve(data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    updateDo(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/mood-tracker/doing/update`, data, config)
          .then(res => {
            const data = res.data;
            resolve(data);
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            reject(error);
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    deleteDo(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/mood-tracker/doing/delete`, data, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    }
  }
};

export default moodtracker;
