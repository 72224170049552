import axios from "axios";
import Cookies from "js-cookie";

const vacancies = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    vacancies: null,
    vacantID: null,
    category: null,
    applicant: null,
    application: null,
    last_page_vacancies: null
  },
  getters: {},
  mutations: {
    VACANCIES(state, data) {
      state.last_page_vacancies = data.meta.last_page;
    },
    VACANCIES_BYID(state, data) {
      state.vacantID = data;
    },
    MY_APP(state, data) {
      state.application = data;
    },
    CATEGORY(state, data) {
      state.category = data;
    },
    LIST_APPLICANT(state, data) {
      state.applicant = data;
    },
    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //vacancies

    //list all vacancies
    listVacancies(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let title = data.title;
        let city = data.city;
        let category = data.category;
        let page = data.page;
        let per_page = data.per_page;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/vacancies?filter[judul]=${title}&filter[nama_kategori]=${category}&filter[kota_id]=${city}&sort=-created_at&page[number]=${page}&page[size]=${per_page}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("VACANCIES", data);
            if (page > 1) {
              context.state.vacancies.push(...data.data);
            } else {
              context.state.vacancies = data.data;
            }
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all vacancies
    VacanciesByUser(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let title = data.title;
        let city = data.city;
        let category = data.category;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/vacancies?filter[judul]=${title}&filter[nama_kategori]=${category}&filter[kota_id]=${city}&sort=-created_at`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("VACANCIES", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //list all vacancies
    category(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/vacancies-kategori`, config)
          .then(res => {
            resolve(res.data);
            const data = res.data;
            context.commit("CATEGORY", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    //vacancies by id
    getVacant(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/vacancies?filter[id]=${data}&include=dataKota`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("VACANCIES_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },

    // create vacancies.
    createVacancies(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/vacancies/create`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update vacancies.
    updateVacancies(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/admin/vacancies/${id}/update`, data.data, config)
          .then(res => {
            const data = res.data;
            console.log(res.data);
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete vacancies by id.
    deleteVacancies(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(
            `${API_URL}/admin/vacancies/${data}/delete`,
            { id: data },
            config
          )
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // aply vacancies.
    applyVacancies(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/vacancies/${id}/apply`, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    listApplicant(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/vacancies/applyers?filter[apply]=${data.search}&page[number]=${data.page}&page[size]=${data.limit}`,
            config
          )
          .then(res => {
            resolve(res.data);
            context.commit("LIST_APPLICANT", res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },
    myVacancies(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let title = data.title;
        let city = data.city;
        let category = data.category;
        let id = data.id;
        let page = data.page;
        let per_page = data.per_page;
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/vacancies?filter[user_id]=${id}&filter[judul]=${title}&filter[nama_kategori]=${category}&filter[kota_id]=${city}&sort=-created_at&page[number]=${page}&page[size]=${per_page}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("VACANCIES", data);
            if (page > 1) {
              context.state.vacancies.push(...data.data);
            } else {
              context.state.vacancies = data.data;
            }
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    myApps(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/vacancies/my-apply`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MY_APP", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    }
  }
};

export default vacancies;
