<template>
  <v-row justify="start">
    <v-dialog v-model="dialogHelp" scrollable persistent max-width="350px">
      <v-card class="radius-card">
        <v-card-text class="pa-2">
          <v-container>
            <div class="d-flex justify-center mb-3">
              <v-btn depressed fab dark color="blue">
                <v-icon>mdi-email-check</v-icon>
              </v-btn>
            </div>
            <div>
              <h3 class="text-center">
                Kirimkan pertanyaan atau keluhan Anda ke
              </h3>
            </div>
            <div
              class="ma-1 pa-2 rounded-lg blue lighten-5 __hover"
              @click="toEmail"
            >
              <h3 class="text-center blue--text">
                alumnimapropsiugm@gmail.com
              </h3>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('close')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "herupimi",

  props: ["dialogHelp"],

  data() {
    return {
      loading: false,
      recomendation: ""
    };
  },

  methods: {
    toEmail() {
      window.location.href = "mailto:alumnimapropsiugm@gmail.com";
    }
  }
};
</script>

<style scoped>
.__hover:hover {
  cursor: pointer;
}
</style>
