import axios from "axios";
import Cookies from "js-cookie";

const feeds = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    feeds: null,
    getFeeds: null,
    feedsDetail: null,
    activity: null,
    last_page_feed: null,
    meta: null
  },
  getters: {},
  mutations: {
    FEEDS(state, data) {
      state.last_page_feed = data.meta.last_page;
      state.meta = data.meta;
    },
    MY_FEEDS(state, data) {
      state.getFeeds = data;
    },
    FEEDS_BYID(state, data) {
      state.feedsDetail = data;
    },

    ACTIVITY(state, data) {
      state.activity = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    }
  },
  actions: {
    //my feeds
    myFeeds(context) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(
            `${API_URL}/user/feeds?include=dataUser,likers,comments&sort=-created_at`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("MY_FEEDS", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
          });
      });
    },
    //list all feeds
    listFeeds(context, paging) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        let limit = paging.per_page ? paging.per_page : 0;
        let page = paging.page ? paging.page : 0;
        axios
          .get(
            `${API_URL}/user/feeds-list?include=dataUser,likers,comments&sort=-created_at&page[number]=${page}&page[size]=${limit}`,
            config
          )
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FEEDS", data);
            if (page > 1) {
              context.state.feeds.push(...data.data);
            } else {
              context.state.feeds = data.data;
            }
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
          });
      });
    },

    //feeds by id
    feedById(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/feeds/${data}/view`, config)
          .then(res => {
            resolve(res);
            const data = res.data;
            context.commit("FEEDS_BYID", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            reject(error);
            console.log(error);
          });
      });
    },

    // you can create even feeds just by API lol.
    createFeeds(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/feeds/create`, data, config)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // update feeds.
    updateFeeds(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data.id;
        axios
          .post(`${API_URL}/user/feeds/${id}/update`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    // delete university by id.
    deleteFeeds(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        const id = data;
        axios
          .post(`${API_URL}/user/feeds/${id}/delete`, data, config)
          .then(res => {
            const data = res.data;
            context.commit("SOLVE_MSG", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    likeFeed(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/feeds/${id}/like`, { id: id }, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    unlikeFeed(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/feeds/${id}/unlike`, { id: id }, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    //list activity
    listActivity(context) {
      return new Promise(resolve => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/user/recent-activity/Feed`, config)
          .then(res => {
            const data = res.data;
            console.log("inilo");
            console.log(data);
            context.commit("ACTIVITY", data);
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    comment(context, data) {
      return new Promise(resolve => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let model = data.model;
        let content = { commentable_id: data.id, content: data.content };
        console.log(content);
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/comment/create/${model}`, content, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    updateCommentFeed(context, data) {
      console.log(data);
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let id = data.id;
        let content = { content: data.content };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/comment/${id}/update`, content, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error.response.data);
            context.commit("ERR_MSG", error.response.data);
          });
      });
    },

    deleteCommentFeed(context, id) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };

        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/user/comment/${id}/delete`, { id: id }, config)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response.data);
          });
      });
    }
  }
};
export default feeds;
