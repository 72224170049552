import axios from "axios";
import Cookies from "js-cookie";

const cust = {
  namespaced: true,
  state: {
    errMsg: "",
    solveMsg: "",
    registran: null,
    myProfile: null,
    location: null,
    additionalLocation: null,
    university: null,
    field: null,
    educational: null,
    experiences: null,
    skills: null,
    listRecomendation: null,
    photo: null,
    cv: null,
    listPortfolio: null
  },
  getters: {},
  mutations: {
    MY_PROFILE(state, data) {
      state.myProfile = data;
    },
    LOCATION_CUSTOMER(state, data) {
      state.location = data;
    },
    PHOTO(state, data) {
      state.photo = data;
    },
    CV(state, data) {
      state.cv = data;
    },

    LIST_PORTFOLIO(state, data) {
      state.listPortfolio = data;
    },

    EDUCATIONAL(state, data) {
      state.educational = data;
    },

    EXPERIENCES(state, data) {
      state.experiences = data;
    },

    SKILLS(state, data) {
      state.skills = data;
    },

    REGISTRAN(state, data) {
      state.registran = data;
    },

    RECOMENDATION(state, data) {
      state.listRecomendation = data;
    },

    SOLVE_MSG(state, data) {
      state.solveMsg = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error.status;
    }
  },
  actions: {
    // my prfofile
    myProfile(context) {
      return new Promise((resolve, reject) => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .get(`${API_URL}/public/my-profile`, config)
          .then(res => {
            resolve(res.data.data[0]);
            const data = res.data.data[0];
            context.commit("MY_PROFILE", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error.response);
            reject(error);
          });
      });
    },
    getDomisili(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/admin/kota/${data}/view`, config)
          .then(res => {
            resolve(res.data.data);
            const data = res.data.data;
            context.commit("LOCATION_CUSTOMER", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    updateUser(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/profile/update`, data, config)
          .then(res => {
            resolve(res.data.data);
            const data = res.data.data;
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    },
    updatePwdPublic(context, data) {
      return new Promise(resolve => {
        let config = {
          // Define request header
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`
          }
        };
        let API_URL = process.env.VUE_APP_ENV;
        axios
          .post(`${API_URL}/public/profile/update`, data, config)
          .then(res => {
            resolve(res.data.data);
            const data = res.data.data;
            context.commit("SOLVE_MSG", data);
          })
          .catch(error => {
            context.commit("ERR_MSG", error.response);
            console.log(error);
          });
      });
    }
  }
};
export default cust;
